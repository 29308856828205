import React, { Suspense, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router';
import * as Sentry from '@sentry/react';
import { useServiceWorkerContext } from 'goldilocks/context/ServiceWorkerContext';
import { CurrentVersionQuery } from 'goldilocks/graphql/version/queries.graphql';
import { lazyWithRetry as lazy } from '@/utilities/lazyWithRetry';
import MakeRouteWithSubRoutes from './makeRouteWithSubRoutes';

const ScoringRuleset = lazy(() => import('goldilocks/pages/LeadScoringRules/CreateEditScoringRuleset'));
const DashboardAnalytics = lazy(() => import('goldilocks/pages/Dashboards/Analytics'));
const DashboardAttribution = lazy(() => import('goldilocks/pages/Dashboards/Attribution'));
const DashboardAutomationComparison = lazy(
  () => import('goldilocks/pages/Dashboards/AutomationComparison/AutomationComparisonDashboard')
);
const AutomationPerformanceDashboard = lazy(() => import('goldilocks/pages/Dashboards/AutomationPerformance'));
const MyLists = lazy(() => import('./pages/Lists/MyLists'));
const MarketingContributionDashboard = lazy(() => import('goldilocks/pages/Dashboards/MarketingContribution'));
const EmailEngagementDashboard = lazy(() => import('goldilocks/pages/Dashboards/EmailEngagement'));
const TrafficSourcesDashboard = lazy(() => import('goldilocks/pages/Dashboards/TrafficSources'));
const BounceReasonsDashboard = lazy(() => import('goldilocks/pages/Dashboards/BounceReasons'));
const Account = lazy(() => import('goldilocks/pages/Accounts/Account'));
const LandingPageDomainWizard = lazy(() => import('@/components/Settings/Account/Setup/Wizards/LandingPageDomain'));
const JoyRideExample = lazy(() => import('@/components/Examples/JoyRide'));
const DashletExamples = lazy(() => import('@/components/Examples/Dashlets'));
const ManageCustomObject = lazy(() => import('goldilocks/pages/CustomObjects/ManageCustomObject'));
const MyCustomObjectX = lazy(() => import('goldilocks/pages/CustomObjects/MyObjectX'));
const DashboardHome = lazy(() => import('goldilocks/pages/Dashboards'));
const Home = lazy(() => import('./pages/Home'));
const NoMatch = lazy(() => import('./pages/NoMatch'));
const Examples = lazy(() => import('./pages/Examples'));
const OldDashboardDownloads = lazy(() => import('./pages/Dashboards/Downloads/OldDashboardDownloads'));
const DashboardDownloads = lazy(() => import('./pages/Dashboards/Downloads/DashboardDownloads'));
const Segments = lazy(() => import('./pages/GlobalSegments/MyGlobalSegments'));
const Actions = lazy(() => import('./pages/AutomatedActions'));
const CreateTasks = lazy(() => import('goldilocks/pages/AutomatedActions/CreateCrmTasksActions'));
const MyCampaigns = lazy(() => import('./pages/Automations/MyAutomations'));
const Approvals = lazy(() => import('./pages/Automations/Approvals'));
const WorkFlowsCreateEdit = lazy(() => import('./pages/Automations/CreateEdit'));
const DripSeriesCreateEdit = lazy(() => import('goldilocks/pages/Automations/DripSeries'));
const MarketingCampaigns = lazy(() => import('goldilocks/pages/MarketingCampaigns'));
const MyHotJobAlerts = lazy(() => import('./pages/HotJobAlerts/MyHotJobAlerts'));
const MyRecurringJobSummaries = lazy(() => import('./pages/RecurringJobSummaries/MyRecurringJobSummaries'));
const MySms = lazy(() => import('./pages/SmsMessages/MySms'));
const SocialAccounts = lazy(() => import('./pages/SocialPosts/SocialAccounts'));
const SocialPosts = lazy(() => import('./pages/SocialPosts'));
const SocialPostsCalendarView = lazy(() => import('./pages/SocialPosts/SocialPostsCalendarView/index'));
const SocialPostsListView = lazy(() => import('./pages/SocialPosts/SocialPostsListView/index'));
const WebsiteVisitorsDashboard = lazy(() => import('./pages/Dashboards/WebsiteVisitors'));
const PopularWebPages = lazy(() => import('goldilocks/pages/Dashboards/PopularWebPages'));

const TrafficSources = lazy(() => import('./pages/Dashboards/TrafficSources'));
const ContactsDashboard = lazy(() => import('./pages/Dashboards/Contacts'));
const EmailClientsDashboard = lazy(() => import('./pages/Dashboards/EmailClients'));
const ListDashboard = lazy(() => import('./pages/Dashboards/MarketingLists/index'));
const MyWebinars = lazy(() => import('./pages/Webinars/MyWebinars'));
const WebinarDashboard = lazy(() => import('./pages/Dashboards/Webinars/index'));
const LenovoDashboard = lazy(() => import('./pages/Dashboards/Lenovo/index'));
const AutomationOverviewDashboard = lazy(() => import('./pages/Dashboards/AutomationOverview'));
const SocialPageOverviewDashboard = lazy(() => import('./pages/Dashboards/SocialPageOverview'));
const DashboardSubscription = lazy(() => import('./pages/Dashboards/SubscriptionLists/index'));
const DashboardsConversations = lazy(() => import('./pages/Dashboards/Conversations/DashboardsConversations'));
// const MyLeads = lazy(
//   () => import( './pages/Dashboards/MyLeads/MyLeads')
// );
const SalesTasks = lazy(() => import('./pages/Sales/Tasks'));
const Opportunities = lazy(() => import('./pages/Sales/Opportunities'));
const Calendar = lazy(() => import('./pages/Calendar'));
const UrlBuilder = lazy(() => import('./pages/UrlBuilder'));
const MyAccounts = lazy(() => import('./pages/Accounts/MyAccounts'));
const MyAlerts = lazy(() => import('./pages/Alerts/MyAlerts'));
const MyCustomObjects = lazy(() => import('./pages/Accounts/CustomObjects/MyCustomObjects'));
const MyForms = lazy(() => import('goldilocks/pages/NextGenForms/components/FormTable'));
const FormSubmissions = lazy(() => import('@/components/Forms/FormSubmissionsTable'));
const FormSubmissionExportHistory = lazy(() => import('goldilocks/pages/Forms/FormSubmissions/ExportHistory'));
const FormBuilder = lazy(() => import('goldilocks/pages/Builders/Forms/EditForm'));
const MyLandingPages = lazy(() => import('goldilocks/pages/LandingPages/MyLandingPages'));
const LandingPageBuilder = lazy(() => import('goldilocks/pages/Builders/LandingPages/EditLandingPage'));
const MyWebsitePersonalizations = lazy(
  () => import('goldilocks/pages/WebsitePersonalizations/MyWebsitePersonalizations')
);
const WebsitePersonalizationBuilder = lazy(
  () => import('goldilocks/pages/Builders/WebsitePersonalization/EditWebsitePersonalization')
);
const MyNextGenEmails = lazy(() => import('goldilocks/pages/NextGenEmails/components/EmailTable'));
const EmailBuilder = lazy(() => import('goldilocks/pages/Builders/Emails/components/EditEmail'));
const EmailHeatmap = lazy(() => import('goldilocks/pages/Emails/EmailHeatmap'));
const ClassicForms = lazy(() => import('./pages/Forms/MyForms'));
const FormsCreateEdit = lazy(() => import('./pages/Forms/CreateEdit'));
const MyMappedForms = lazy(() => import('./pages/MappedForms/MyMappedForms'));
const CreateEditMappedForm = lazy(() => import('./pages/MappedForms/CreateEditMappedForm'));
const MappedFormConversions = lazy(() => import('./pages/MappedForms/MappedFormConversions'));
const Settings = lazy(() => import('./pages/Settings'));
const MyClassicLandingPages = lazy(() => import('goldilocks/pages/ClassicLandingPages/MyLandingPages'));
const CustomHtmlLandingPageCreateEdit = lazy(() => import('goldilocks/pages/ClassicLandingPages/CustomHtmlCreateEdit'));
const MySubscriptions = lazy(() => import('./pages/Subscriptions/MySubscriptions'));
const MyEmails = lazy(() => import('./pages/Emails/MyEmails'));
const EmailDashboard = lazy(() => import('./pages/Dashboards/EmailPerformance'));
const EmailTemplates = lazy(() => import('./pages/Emails/EmailTemplates'));
const EmailsCreateEdit = lazy(() => import('./pages/Emails/CreateEdit'));
const SendConversationEmail = lazy(() => import('./pages/Emails/SendConversationEmail'));
const MyLeadScoringRules = lazy(() => import('./pages/LeadScoringRules/MyLeadScoringRules'));
const MyReports = lazy(() => import('./pages/Reports/MyReports'));
const CreateEditReport = lazy(() => import('./pages/Reports/CreateEdit'));
const MyUploadHistory = lazy(() => import('goldilocks/pages/Contacts/UploadHistory'));
const DownloadCenter = lazy(() => import('./pages/Reports/DownloadCenter'));
const DynamicsSettings = lazy(
  () => import(/* webpackPrefetch: true */ '@/components/Settings/Account/Integrations/Dynamics')
);
const SalesforceSettings = lazy(
  () => import(/* webpackPrefetch: true */ '@/components/Settings/Account/Integrations/Salesforce')
);
const CrmSettings = lazy(() => import(/* webpackPrefetch: true */ '@/components/Settings/Account/Integrations/CRM'));
const SugarCrmSettings = lazy(
  () => import(/* webpackPrefetch: true */ '@/components/Settings/Account/Integrations/SugarCrm')
);
const Contact = lazy(() => import('goldilocks/pages/Contacts/ContactDetails'));
const LenovoContact = lazy(() => import('goldilocks/pages/Dashboards/Lenovo/components/ContactDetailsLenovo'));
const AuthLayout = lazy(() => import('./layouts/AuthLayout'));
const ProductRoadmap = lazy(() => import('./pages/ProductRoadmap'));
const Notifications = lazy(() => import('./pages/Notifications'));
const Backdoor = lazy(() => import('@/components/Auth/Backdoor'));
const SingleSignOn = lazy(() => import('@/components/Auth/SingleSignOn'));
const BillingStatement = lazy(() => import('@/components/Settings/Account/Billing/BillingStatement'));
const MyContacts = lazy(() => import('./pages/Contacts/MyContacts'));
const UploadContacts = lazy(() => import('goldilocks/pages/Contacts/Upload'));
const ContactExportHistory = lazy(() => import('goldilocks/pages/Contacts/ExportHistory'));
const BouncedContacts = lazy(() => import('goldilocks/pages/Contacts/BouncedContacts'));

const SunsetContacts = lazy(() => import('goldilocks/pages/Contacts/SunsetContacts/SunsetContactsTable'));

const GoogleAdsOauthComplete = lazy(() => import('goldilocks/pages/GoogleAdsOauthComplete'));

const SlackOauthComplete = lazy(() => import('goldilocks/pages/SlackOauthComplete'));

const UnsubscribedContacts = lazy(() => import('goldilocks/pages/Contacts/UnsubscribedContacts'));
const ReactFlowExamples = lazy(() => import('./pages/Examples/ReactFlow'));
const ChartExamples = lazy(() => import('./pages/Examples/charts'));
const Workflow = lazy(() => import('./pages/Automations'));
const Flow = lazy(() => import('./pages/Flow'));
const DndKitGrid = lazy(() => import('@/components/Examples/DndKitGrid'));
const ConversionsIframeDashboard = lazy(() => import('./pages/Dashboards/ConversionsIframe'));
const FormSubmissionsDashboard = lazy(() => import('./pages/Dashboards/FormSubmissions'));
const ReactHookForm = lazy(() => import('./pages/Examples/ReactHookForm'));
const LexicalExample = lazy(() => import('./pages/Examples/Lexical'));
const AccountSwap = lazy(() => import('./pages/Examples/AccountSwap'));
const DataGridExample = lazy(() => import('./pages/Examples/DataGrid'));
const CreateEditGlobalSegment = lazy(() => import('goldilocks/pages/GlobalSegments/CreateEditGlobalSegment'));
const OauthCreateEdit = lazy(() => import('@/components/Settings/Account/OAuthApps/CreateEdit'));
const ElFinderFileManager = lazy(() => import('./pages/FileManagerElFinder'));
const FileManager = lazy(() => import('./pages/FileManager'));
const PricePlans = lazy(() => import('./pages/PricePlans'));
const DataEnrichmentIntegrations = lazy(() => import('./pages/DataEnrichment/Integrations'));
const DataEnrichmentRevisions = lazy(() => import('./pages/DataEnrichment/Revisions'));
const DataEnrichmentHistory = lazy(() => import('./pages/DataEnrichment/History'));
const MyBrandKits = lazy(() => import('@/components/BrandKit/MyBrandKits'));
const EditBrandKit = lazy(() => import('@/components/BrandKit/EditBrandKit'));
const MyLeads = lazy(() => import('./pages/Leads/MyLeads'));
const MyOpportunities = lazy(() => import('./pages/Opportunities/MyOpportunities'));
const MyPersonAccounts = lazy(() => import('./pages/PersonAccounts/MyPersonAccounts'));
const PersonAccount = lazy(() => import('goldilocks/pages/PersonAccounts/components/PersonAccount'));
const MyActions = lazy(() => import('./pages/Actions/MyActions'));
const SunsetContactsAutomation = lazy(() => import('./pages/Automations/SunsetContacts'));
const NextGenWorkflow = lazy(() => import('./pages/Automations/NextGenWorkflows'));
const AbandonedCartWorkflow = lazy(() => import('./pages/Automations/AbandonedCart'));

const routes = [
  {
    path: '/',
    exact: true,
    redirect: <Redirect to="/home" />,
  },
  {
    path: '/login',
    exact: true,
    component: AuthLayout,
  },
  {
    path: '/accounts',
    component: MyAccounts,
  },
  {
    path: '/account',
    component: Account,
  },
  {
    path: ['/contacts', '/contacts/createEdit', '/contacts/view/createEdit'],
    exact: true,
    component: MyContacts,
  },
  {
    path: '/contacts/import',
    component: UploadContacts,
  },
  {
    path: '/contacts/export-history',
    component: ContactExportHistory,
  },
  {
    path: '/contacts/bounced',
    component: BouncedContacts,
  },
  {
    path: '/contacts/sunset',
    component: SunsetContacts,
  },
  {
    path: '/contacts/unsubscribed',
    component: UnsubscribedContacts,
  },
  {
    path: '/contact',
    component: Contact,
  },
  {
    path: '/sales/tasks',
    component: SalesTasks,
  },
  {
    path: '/sales/opportunities',
    component: Opportunities,
  },
  {
    path: '/marketing-center/brand-kits',
    component: MyBrandKits,
  },
  {
    path: '/marketing-center/brand-kit/edit',
    component: EditBrandKit,
  },
  {
    path: '/marketing-center/next-gen-landing-pages/edit',
    component: LandingPageBuilder,
    exact: true,
  },
  {
    path: '/marketing-center/next-gen-landing-pages',
    component: MyLandingPages,
  },
  {
    path: '/marketing-center/website-personalizations/edit',
    component: WebsitePersonalizationBuilder,
    exact: true,
  },
  {
    path: '/marketing-center/website-personalizations',
    component: MyWebsitePersonalizations,
  },
  {
    path: '/marketing-center/calendar',
    exact: true,
    component: Calendar,
  },
  {
    path: '/marketing-center/url-builder',
    exact: true,
    component: UrlBuilder,
  },
  {
    path: '/custom-objects',
    exact: true,
    component: MyCustomObjects,
  },
  {
    path: '/custom-object',
    exact: true,
    component: ManageCustomObject,
  },
  {
    path: '/custom-object/datagrid',
    component: MyCustomObjectX,
  },
  {
    path: '/contact-imports',
    exact: true,
    component: MyUploadHistory,
  },
  {
    path: '/marketing-center/lead-scoring',
    exact: true,
    component: MyLeadScoringRules,
  },
  {
    path: '/marketing-center/scoring-ruleset',
    component: ScoringRuleset,
  },
  {
    path: '/marketing-center/lists',
    component: MyLists,
  },
  {
    path: '/marketing-center/subscriptions',
    component: MySubscriptions,
  },
  {
    path: '/marketing-center/landing-pages/customHtml/createEdit',
    component: CustomHtmlLandingPageCreateEdit,
  },
  {
    path: '/marketing-center/landing-pages',
    component: MyClassicLandingPages,
  },
  {
    path: '/reporting/dashboards',
    exact: true,
    component: DashboardHome,
  },
  {
    path: '/marketing-center/actions',
    exact: true,
    component: Actions,
  },
  {
    path: '/marketing-center/actions/create-tasks',
    component: CreateTasks,
  },
  {
    path: '/marketing-center/automations/createEdit',
    exact: true,
    component: WorkFlowsCreateEdit,
  },
  {
    path: '/marketing-center/automations/sunsetContacts/edit',
    exact: true,
    component: SunsetContactsAutomation,
  },
  {
    path: '/marketing-center/automation',
    exact: true,
    component: Workflow,
  },
  {
    path: '/marketing-center/automations/approvals',
    exact: true,
    component: Approvals,
  },
  {
    path: '/marketing-center/automation/abandoned-cart/createEdit',
    exact: true,
    component: AbandonedCartWorkflow,
  },
  {
    path: '/marketing-center/automations',
    component: MyCampaigns,
  },
  {
    path: '/marketing-center/drip-series/createEdit',
    component: DripSeriesCreateEdit,
  },
  {
    path: '/marketing-center/workflows/createEdit',
    component: NextGenWorkflow,
  },
  {
    path: '/marketing-center/marketing-campaigns',
    component: MarketingCampaigns,
  },
  {
    path: '/marketing-center/hot-job-alerts',
    component: MyHotJobAlerts,
  },
  {
    path: '/marketing-center/recurring-job-summaries',
    component: MyRecurringJobSummaries,
  },
  {
    path: '/marketing-center/my-actions',
    component: MyActions,
  },
  {
    path: '/marketing-list',
    component: ListDashboard,
  },
  {
    path: '/marketing-center/webinars',
    component: MyWebinars,
  },
  {
    path: '/reporting/dashboards/webinar',
    component: WebinarDashboard,
  },
  {
    path: '/lenovo/contact',
    component: LenovoContact,
  },
  {
    path: '/lenovo/reporting/dashboard',
    component: LenovoDashboard,
  },
  {
    path: '/reporting/dashboards/automation-overview',
    component: AutomationOverviewDashboard,
  },
  {
    path: '/reporting/dashboards/social-page-overview',
    component: SocialPageOverviewDashboard,
  },
  {
    path: '/subscription-list',
    component: DashboardSubscription,
  },
  {
    path: '/reporting/dashboards/analytics',
    exact: true,
    component: DashboardAnalytics,
  },
  {
    path: '/reporting/dashboards/attribution',
    exact: true,
    component: DashboardAttribution,
  },
  {
    path: '/reporting/dashboards/automation-comparison',
    exact: true,
    component: DashboardAutomationComparison,
  },
  {
    path: '/reporting/dashboards/automation-performance',
    exact: true,
    component: AutomationPerformanceDashboard,
  },
  {
    path: '/reporting/dashboards/one-off-emails',
    exact: true,
    component: DashboardsConversations,
  },
  {
    path: '/reporting/dashboards/conversionsiframe',
    exact: true,
    component: ConversionsIframeDashboard,
  },
  {
    path: '/reporting/dashboards/form-submissions',
    component: FormSubmissionsDashboard,
  },
  {
    path: '/reporting/dashboards/new-downloads',
    component: DashboardDownloads,
  },
  {
    path: '/reporting/dashboards/downloads',
    component: OldDashboardDownloads,
  },
  {
    path: '/marketing-center/email-templates',
    exact: true,
    component: EmailTemplates,
  },
  {
    path: '/marketing-center/emails/createEdit',
    exact: true,
    component: EmailsCreateEdit,
  },
  {
    path: '/marketing-center/emails/send',
    exact: true,
    component: SendConversationEmail,
  },
  {
    path: '/marketing-center/emails/dashboard',
    component: EmailDashboard,
  },
  {
    path: '/marketing-center/emails/email-heatmap',
    component: EmailHeatmap,
  },
  {
    path: '/marketing-center/emails',
    component: MyEmails,
  },
  {
    path: '/marketing-center/next-gen-emails/edit',
    exact: true,
    component: EmailBuilder,
  },
  {
    path: '/marketing-center/next-gen-emails',
    component: MyNextGenEmails,
  },
  {
    path: '/marketing-center/forms/edit',
    exact: true,
    component: FormBuilder,
  },
  {
    path: '/marketing-center/forms',
    component: MyForms,
  },
  {
    path: '/marketing-center/form-submissions',
    exact: true,
    component: FormSubmissions,
  },
  {
    path: '/marketing-center/form-submissions/export-history',
    component: FormSubmissionExportHistory,
  },
  {
    path: '/marketing-center/classic-forms/createEdit',
    exact: true,
    component: FormsCreateEdit,
  },
  {
    path: '/marketing-center/classic-forms',
    component: ClassicForms,
  },
  {
    path: '/reporting/reports/alerts',
    component: MyAlerts,
  },
  {
    path: '/marketing-center/mapped-forms',
    exact: true,
    component: MyMappedForms,
  },
  {
    path: '/marketing-center/mapped-forms/createEdit',
    component: CreateEditMappedForm,
  },
  {
    path: '/marketing-center/mapped-forms/conversions',
    exact: true,
    component: MappedFormConversions,
  },
  {
    path: '/reporting/reports/scheduled-reports',
    exact: true,
    component: MyReports,
  },
  {
    path: '/reporting/reports/createEdit',
    exact: true,
    component: CreateEditReport,
  },
  {
    path: '/reporting/reports/download-center',
    exact: true,
    component: DownloadCenter,
  },
  {
    path: '/reporting/dashboards/contacts',
    component: ContactsDashboard,
  },
  {
    path: '/reporting/dashboards/email-clients',
    exact: true,
    component: EmailClientsDashboard,
  },
  {
    path: '/reporting/dashboards/bounce-reasons',
    exact: true,
    component: BounceReasonsDashboard,
  },
  {
    path: '/reporting/dashboards/marketing-contribution',
    exact: true,
    component: MarketingContributionDashboard,
  },
  {
    path: '/reporting/dashboards/email-engagement',
    exact: true,
    component: EmailEngagementDashboard,
  },
  {
    path: '/reporting/dashboards/traffic-sources',
    exact: true,
    component: TrafficSourcesDashboard,
  },
  {
    path: '/home',
    exact: true,
    component: Home,
  },
  {
    path: '/examples',
    exact: true,
    component: Examples,
  },
  {
    path: '/examples/flow',
    exact: true,
    component: ReactFlowExamples,
  },
  {
    path: '/examples/flow-new',
    exact: true,
    component: Flow,
  },
  {
    path: '/examples/charts',
    exact: true,
    component: ChartExamples,
  },
  {
    path: '/examples/dndkitgrid',
    exact: true,
    component: DndKitGrid,
  },
  {
    path: '/examples/joyride',
    exact: true,
    component: JoyRideExample,
  },
  {
    path: '/examples/dashlets',
    exact: true,
    component: DashletExamples,
  },
  {
    path: '/examples/hookform',
    exact: true,
    component: ReactHookForm,
  },
  {
    path: '/examples/lexical',
    exact: true,
    component: LexicalExample,
  },
  {
    path: '/examples/swap',
    exact: true,
    component: AccountSwap,
  },
  {
    path: '/examples/datagrid',
    exact: true,
    component: DataGridExample,
  },
  // {
  //   path: '/reporting/dashboards/my-leads',
  //   exact: true,
  //   component: MyLeads,
  // },
  {
    path: '/marketing-center/segments',
    exact: true,
    component: Segments,
  },
  {
    path: '/marketing-center/segments/createEdit',
    exact: true,
    component: CreateEditGlobalSegment,
  },
  {
    path: '/marketing-center/assets',
    exact: true,
    component: ElFinderFileManager,
  },
  {
    path: '/marketing-center/file-manager',
    component: FileManager,
  },
  {
    path: '/marketing-center/sms',
    component: MySms,
  },
  {
    path: '/marketing-center/social/accounts',
    component: SocialAccounts,
    exact: true,
  },
  {
    path: [
      '/marketing-center/social/posts/create',
      '/marketing-center/social/post/edit',
      '/marketing-center/social/post/clone',
    ],
    component: SocialPosts,
  },
  {
    path: '/marketing-center/social/calendar',
    component: SocialPostsCalendarView,
    exact: true,
  },
  {
    path: '/marketing-center/social/list-view',
    component: SocialPostsListView,
    exact: true,
  },
  {
    path: '/reporting/dashboards/website-visitors',
    component: WebsiteVisitorsDashboard,
  },
  {
    path: '/reporting/dashboards/pages',
    exact: true,
    component: PopularWebPages,
  },
  {
    path: '/reporting/dashboards/traffic-sources',
    exact: true,
    component: TrafficSources,
  },
  {
    path: '/settings/account/oauth-apps/createEdit',
    component: OauthCreateEdit,
  },
  {
    path: '/settings/',
    component: Settings,
  },
  {
    path: '/wizard/landing-page-domain',
    exact: true,
    component: LandingPageDomainWizard,
  },
  {
    path: '/billingStatement/',
    component: BillingStatement,
  },
  {
    path: '/setup/account/integrations/dynamics',
    component: DynamicsSettings,
  },
  {
    path: '/setup/account/integrations/salesforce',
    component: SalesforceSettings,
  },
  {
    path: '/setup/account/integrations/crm',
    component: CrmSettings,
  },
  {
    path: '/setup/account/integrations/sugarcrm',
    component: SugarCrmSettings,
  },
  {
    path: '/notifications',
    component: Notifications,
  },
  {
    path: '/plans',
    component: PricePlans,
  },
  {
    path: '/backdoor',
    exact: true,
    component: Backdoor,
  },
  {
    path: '/sso',
    exact: true,
    component: SingleSignOn,
  },
  {
    path: '/roadmap',
    exact: true,
    component: ProductRoadmap,
  },
  {
    path: '/data-enrichment/integrations',
    component: DataEnrichmentIntegrations,
  },
  {
    path: '/data-enrichment/revisions',
    component: DataEnrichmentRevisions,
  },

  {
    path: '/data-enrichment/history',
    component: DataEnrichmentHistory,
  },
  {
    path: '/leads',
    exact: true,
    component: MyLeads,
  },
  {
    path: '/opportunities',
    exact: true,
    component: MyOpportunities,
  },
  {
    path: '/person-accounts',
    exact: true,
    component: MyPersonAccounts,
  },
  {
    path: '/person-account',
    component: PersonAccount,
  },
  {
    path: '/google-ads-is-connected',
    component: GoogleAdsOauthComplete,
  },
  {
    path: '/slack-is-connected',
    component: SlackOauthComplete,
  },
  {
    path: '/:NotFound',
    component: NoMatch,
  },
];

const Routes = () => {
  const { updateSW, oldCorePath, setOldCorePath } = useServiceWorkerContext();
  const location = useLocation();
  const { data, error } = useQuery(CurrentVersionQuery, {
    pollInterval: import.meta.env.VITE_APP_ENVIRONMENT === 'production' ? 30000 : 0,
  });
  const corePath = location.pathname.split('/')[1];
  let updating = false;

  if (error) {
    Sentry.captureException(error);
  }

  try {
    // Only check/perform updates on major route changes.
    if (oldCorePath !== corePath) {
      // if (updateAvailable) {
      //   // If an update is currently ready, update.
      //   // This will render the screen as <div />, perform the update, then render the new page.
      //   updating = true;
      //   updateSW(`${location.pathname}${location.search}`);
      // } else {
      //   // If no update is ready, check for an update.
      //   navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
      // }
      if (data && data.currentVersion?.version) {
        const localVersion = localStorage.getItem('currentVersion');
        if (localVersion !== data.currentVersion?.version) {
          localStorage.setItem('currentVersion', data.currentVersion?.version);
          updating = true;
          updateSW(`${location.pathname}${location.search}`);
        }
      }
    }
  } catch (updateError) {
    // eslint-disable-next-line no-console
    Sentry.captureException(updateError);
  }

  useEffect(() => {
    if (!updating) {
      // Only set the path when we aren't updating.
      // This will prevent state changing, updating becoming false, and then the non updated route rendering for a split second.
      // Instead, <div /> will be rendered until the update has completed.
      setOldCorePath(corePath);
    }
  }, [corePath, setOldCorePath, updating]);

  if (updating) {
    // Only return div when we are currently updating the app.
    // This prevents the component from rendering for a split second only to rerender later.
    return <div />;
  }

  return (
    <Suspense fallback={<div />}>
      <Switch location={location}>
        {routes.map((route) => (
          <MakeRouteWithSubRoutes key={route.path} {...route} />
        ))}
      </Switch>
    </Suspense>
  );
};

export default Routes;
