import React, { ReactElement, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { Box, Dialog, Slide, Tooltip } from '@mui/material';
import { Help } from '@mui/icons-material';
import DocsDialogTitle from '@/apps/Goldilocks/components/DocsDialog/DocsDialogTitle';

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" timeout={1700} ref={ref} {...props} />;
});

const DocsDialog = ({
  customIcon = undefined,
  iconSize,
  tooltipTitle,
  url,
  utmContent,
  utmTerm,
}: {
  customIcon?: ReactElement;
  iconSize?: number;
  tooltipTitle?: string;
  url: string;
  utmContent?: string;
  utmTerm?: string;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  /**
   * getParsedUrl() adds UTM tags so we can track users going to docs from links within the app.
   * This exists because we need to ensure the namedAnchor, if present, remains at the end of the URL.
   */
  const getParsedUrl = () => {
    const urlObject = new URL(url);
    const namedAnchor = urlObject.hash;

    const utmTags = {
      utmCampaign: 'utm_campaign=pamingaDocs',
      utmSource: 'utm_source=pamingaApp',
      utmMedium: 'utm_medium=DocsDialog',
    };

    if (utmContent) {
      // @ts-ignore
      utmTags.utmContent = `utm_content=${utmContent}`;
    }

    if (utmTerm) {
      // @ts-ignore
      utmTags.utmTerm = `utm_content=${utmTerm}`;
    }

    const utmString = Object.values(utmTags).join('&');

    return `${urlObject.origin}${urlObject.pathname}?${utmString}${namedAnchor}`;
  };

  // @ts-ignore
  const styledIcon = React.cloneElement(<Help />, {
    sx: {
      fontSize: iconSize,
      // @ts-ignore
      color: (theme: Theme) => theme.palette.brand.techBlue,
      cursor: 'pointer',
    },
    onClick: () => {
      openDialog();
    },
  });

  let finalCustomIcon;
  if (customIcon) {
    finalCustomIcon = React.cloneElement(customIcon, {
      onClick: () => {
        openDialog();
      },
    });
  }

  return (
    <>
      <Tooltip title={`Paminga Docs: ${tooltipTitle}`} enterDelay={700} arrow placement="top">
        <Box display="flex" alignItems="center">
          {finalCustomIcon || styledIcon}
        </Box>
      </Tooltip>

      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        fullScreen
        // @ts-ignore
        TransitionComponent={Transition}
        aria-labelledby="Paminga Docs"
      >
        <DocsDialogTitle onClose={closeDialog} title="Paminga Docs" url={url} />
        <iframe
          id="pamingaDocsIframe"
          title="Paminga Docs"
          src={getParsedUrl()}
          allowFullScreen
          style={{ position: 'absolute', top: 51, left: 0, width: '100%', height: '95%', border: 'none' }}
        />
      </Dialog>
    </>
  );
};

DocsDialog.defaultProps = {
  customIcon: undefined,
  iconSize: 22,
  tooltipTitle: 'View the Paminga Docs',
  utmContent: null,
  utmTerm: null,
};

export default DocsDialog;
