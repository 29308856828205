import React from 'react';
import { usePopupState, bindHover } from 'material-ui-popup-state/hooks';
import { Box, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { logout } from '@/utilities/jwtLocalstorage';
import Gravatar from '@/components/Gravatar';
import { useCurrentUser } from '@/context/UserContext';
import ThemeToggle from '@/components/Settings/Personal/ThemeToggle';
import { hilcoPids, hilcoSuperUsers } from '@/utilities/CustomerFeatures/hilco';
import CascadingSubmenu from '@/components/Navigation/CascadingSubmenu';
import CascadingMenu from '@/components/Navigation/CascadingMenu';
import PidLock from '@/components/PidLock';
import NrMenuItem from '@/components/Navigation/TopNav/NrMenuItem';
import { iconMappings } from '@/components/Navigation/constants';
import stringUtilities from '@/utilities/String';
import HilcoInstanceSwitcher from '../CustomerFeatures/HilcoInstanceSwitcher';

const NavMyAccount = () => {
  const { currentUser } = useCurrentUser();
  const crmIntegration = currentUser.product.crm_integrations?.[0] || null;
  const crmName =
    crmIntegration?.crm === 'IFS' ? 'IFS' : stringUtilities.capitalizeFirstLetter(crmIntegration?.crm || '');

  const genericCrmIntegration = currentUser.product.crm_integrations?.[0] || null;

  const hasLegacySalesforceCrmIntegration = !!currentUser.product?.salesforce_integration;

  const hasLegacyDynamicsCrmIntegration =
    !!currentUser.product?.dynamics_integration && genericCrmIntegration?.crm !== 'DYNAMICS';

  const hasLegacySugarCrmIntegration =
    !!currentUser.product?.sugar_crm_integration && genericCrmIntegration?.crm !== 'SUGAR_CRM';

  const popupState = usePopupState({ variant: 'popover', popupId: 'navMarketingCenter' });

  const isHilcoSuperUser =
    Object.keys(hilcoPids).includes(String(currentUser.product.id)) &&
    hilcoSuperUsers.some((name) => currentUser.email.toLowerCase().includes(name));

  return (
    <>
      <Box {...bindHover(popupState)}>
        <Gravatar
          email={currentUser.email}
          firstName={currentUser.first_name}
          lastName={currentUser.last_name}
          size={32}
        />
      </Box>
      <CascadingMenu
        popupState={popupState}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box display="flex" justifyContent="center">
          <ThemeToggle />
        </Box>
        {isHilcoSuperUser ? <HilcoInstanceSwitcher /> : null}

        {currentUser.authentication_source === undefined ||
        currentUser.authentication_source === 'USERNAME_PASSWORD' ? (
          <NrMenuItem
            menuItemText="Change Your Password"
            menuItemIcon={iconMappings.password}
            menuItemUrl="/settings/personal/password-change"
          />
        ) : null}
        <NrMenuItem
          menuItemText="Personal Settings"
          menuItemIcon={iconMappings.personOutline}
          menuItemUrl="/settings/personal/profile"
        />
        <NrMenuItem
          menuItemText="Account Settings"
          menuItemIcon={iconMappings.business}
          menuItemUrl="/settings/account/profile"
        />

        {hasLegacyDynamicsCrmIntegration && (
          <CascadingSubmenu
            arrowDirection="left"
            popupId="dynamics"
            title="Dynamics Settings"
            menuItemUrl="/setup/account/integrations/dynamics/settings"
            onClick={popupState.close}
          >
            <NrMenuItem
              menuItemText="Sync Settings"
              menuItemIcon={iconMappings.settings}
              menuItemUrl="/setup/account/integrations/dynamics/settings"
            />
            <CascadingSubmenu
              arrowDirection="left"
              popupId="dynamicsFieldMappings"
              title="Field Mappings"
              menuItemUrl="/setup/account/integrations/dynamics/field-mappings"
              onClick={popupState.close}
            >
              <NrMenuItem
                menuItemText="Account Mappings"
                menuItemIcon={iconMappings.altRoute}
                menuItemUrl="/setup/account/integrations/dynamics/field-mappings#account"
              />
              <NrMenuItem
                menuItemText="Contact Mappings"
                menuItemIcon={iconMappings.altRoute}
                menuItemUrl="/setup/account/integrations/dynamics/field-mappings#contact"
              />
              <NrMenuItem
                menuItemText="Lead Mappings"
                menuItemIcon={iconMappings.altRoute}
                menuItemUrl="/setup/account/integrations/dynamics/field-mappings#lead"
              />
            </CascadingSubmenu>

            <CascadingSubmenu
              arrowDirection="left"
              popupId="dynamicsActions"
              title="Actions"
              menuItemUrl="/setup/account/integrations/dynamics/actions#contact"
              onClick={popupState.close}
            >
              <NrMenuItem
                menuItemText="Contact Actions"
                menuItemIcon={iconMappings.directionsRun}
                menuItemUrl="/setup/account/integrations/dynamics/actions#contact"
              />
              <NrMenuItem
                menuItemText="Lead Actions"
                menuItemIcon={iconMappings.directionsRun}
                menuItemUrl="/setup/account/integrations/dynamics/actions#lead"
              />
            </CascadingSubmenu>

            <PidLock additionalPIDs={[19945 /* Fastenal */]}>
              <NrMenuItem
                menuItemText="Sync Status"
                menuItemIcon={iconMappings.manageHistory}
                menuItemUrl="/setup/account/integrations/dynamics/sync-status"
              />
            </PidLock>

            <PidLock additionalPIDs={[19945 /* Fastenal */]}>
              <NrMenuItem
                menuItemText="Sync History"
                menuItemIcon={iconMappings.history}
                menuItemUrl="/setup/account/integrations/dynamics/sync-history"
              />
            </PidLock>

            <NrMenuItem
              menuItemText="Sync Errors"
              menuItemIcon={iconMappings.errorOutline}
              menuItemUrl="/setup/account/integrations/dynamics/errors"
            />
          </CascadingSubmenu>
        )}

        {genericCrmIntegration && genericCrmIntegration.crm !== 'SALESFORCE' && (
          <CascadingSubmenu
            arrowDirection="left"
            popupId={`${crmName.toLowerCase()}Settings`}
            title={`${crmName} Settings`}
            menuItemUrl="/setup/account/integrations/crm/settings"
            onClick={popupState.close}
          >
            <NrMenuItem
              menuItemText="Authorization"
              menuItemUrl="/setup/account/integrations/crm/auth"
              menuItemIcon={iconMappings.lock}
            />
            <NrMenuItem
              menuItemText="Sync Settings"
              menuItemIcon={iconMappings.settings}
              menuItemUrl="/setup/account/integrations/crm/settings"
            />

            <PidLock>
              <CascadingSubmenu
                arrowDirection="left"
                popupId={`${crmName.toLowerCase()}FieldMappings`}
                title="Field Mappings"
                menuItemUrl="/setup/account/integrations/crm/field-mappings"
                onClick={popupState.close}
              >
                <NrMenuItem
                  menuItemText="Account Mappings"
                  menuItemIcon={iconMappings.altRoute}
                  menuItemUrl="/setup/account/integrations/crm/field-mappings#account"
                />
                <NrMenuItem
                  menuItemText="Contact Mappings"
                  menuItemIcon={iconMappings.altRoute}
                  menuItemUrl="/setup/account/integrations/crm/field-mappings#contact"
                />
                <NrMenuItem
                  menuItemText="Lead Mappings"
                  menuItemIcon={iconMappings.altRoute}
                  menuItemUrl="/setup/account/integrations/crm/field-mappings#lead"
                />
              </CascadingSubmenu>
            </PidLock>

            <CascadingSubmenu
              arrowDirection="left"
              popupId={`${crmName.toLowerCase()}Actions`}
              title="Actions"
              menuItemUrl="/setup/account/integrations/crm/actions#contact"
              onClick={popupState.close}
            >
              <NrMenuItem
                menuItemText="Contact Actions"
                menuItemIcon={iconMappings.directionsRun}
                menuItemUrl="/setup/account/integrations/crm/actions#contact"
              />
              <NrMenuItem
                menuItemText="Lead Actions"
                menuItemIcon={iconMappings.directionsRun}
                menuItemUrl="/setup/account/integrations/crm/actions#lead"
              />
            </CascadingSubmenu>

            <NrMenuItem menuItemText="Sync Status" menuItemUrl="/setup/account/integrations/crm/sync-status" />
            <NrMenuItem menuItemText="Sync History" menuItemUrl="/setup/account/integrations/crm/sync-history" />
          </CascadingSubmenu>
        )}

        {hasLegacySalesforceCrmIntegration && (
          <CascadingSubmenu
            arrowDirection="left"
            popupId="salesforce"
            title="Salesforce Settings"
            menuItemUrl="/setup/account/integrations/salesforce/settings"
            onClick={popupState.close}
          >
            {/* <Box ml="21px"> */}
            <NrMenuItem
              menuItemText="Authorization"
              menuItemUrl="/setup/account/integrations/salesforce/auth"
              menuItemIcon={iconMappings.lock}
            />
            <NrMenuItem
              menuItemText="Sync Settings"
              menuItemIcon={iconMappings.settings}
              menuItemUrl="/setup/account/integrations/salesforce/settings"
            />

            <CascadingSubmenu
              arrowDirection="left"
              popupId="salesforceFieldMappings"
              title="Field Mappings"
              menuItemUrl="/setup/account/integrations/salesforce/field-mappings"
              onClick={popupState.close}
            >
              <NrMenuItem
                menuItemText="Account Mappings"
                menuItemIcon={iconMappings.altRoute}
                menuItemUrl="/setup/account/integrations/salesforce/field-mappings#account"
              />
              <NrMenuItem
                menuItemText="Contact Mappings"
                menuItemIcon={iconMappings.altRoute}
                menuItemUrl="/setup/account/integrations/salesforce/field-mappings#contact"
              />
              <NrMenuItem
                menuItemText="Lead Mappings"
                menuItemIcon={iconMappings.altRoute}
                menuItemUrl="/setup/account/integrations/salesforce/field-mappings#lead"
              />
            </CascadingSubmenu>

            <CascadingSubmenu
              arrowDirection="left"
              popupId="salesforceActions"
              title="Actions"
              menuItemUrl="/setup/account/integrations/salesforce/actions#contact"
              onClick={popupState.close}
            >
              <NrMenuItem
                menuItemText="Contact Actions"
                menuItemIcon={iconMappings.directionsRun}
                menuItemUrl="/setup/account/integrations/salesforce/actions#contact"
              />
              <NrMenuItem
                menuItemText="Lead Actions"
                menuItemIcon={iconMappings.directionsRun}
                menuItemUrl="/setup/account/integrations/salesforce/actions#lead"
              />
            </CascadingSubmenu>

            <NrMenuItem
              menuItemText="Sync Errors"
              menuItemIcon={iconMappings.errorOutline}
              menuItemUrl="/setup/account/integrations/salesforce/errors"
            />
          </CascadingSubmenu>
        )}

        {hasLegacySugarCrmIntegration && (
          <CascadingSubmenu
            arrowDirection="left"
            popupId="SugarCRM"
            title="SugarCRM Settings"
            menuItemUrl="/setup/account/integrations/sugarcrm/settings"
            onClick={popupState.close}
          >
            <NrMenuItem
              menuItemText="Sync Settings"
              menuItemIcon={iconMappings.settings}
              menuItemUrl="/setup/account/integrations/sugarcrm/settings"
            />
            <CascadingSubmenu
              arrowDirection="left"
              popupId="sugarCrmActions"
              title="Actions"
              menuItemUrl="/setup/account/integrations/sugarcrm/actions#contact"
              onClick={popupState.close}
            >
              <NrMenuItem
                menuItemText="Contact Actions"
                menuItemIcon={iconMappings.directionsRun}
                menuItemUrl="/setup/account/integrations/sugarcrm/actions#contact"
              />
              <NrMenuItem
                menuItemText="Lead Actions"
                menuItemIcon={iconMappings.directionsRun}
                menuItemUrl="/setup/account/integrations/sugarcrm/actions#lead"
              />
            </CascadingSubmenu>

            <NrMenuItem
              menuItemText="Sync Errors"
              menuItemIcon={iconMappings.errorOutline}
              menuItemUrl="/setup/account/integrations/sugarcrm/errors"
            />
          </CascadingSubmenu>
        )}

        <NrMenuItem
          menuItemText="Billing"
          menuItemIcon={iconMappings.payment}
          menuItemUrl="/settings/account/billing#price-plan"
        />
        <NrMenuItem menuItemText="Product Roadmap" menuItemIcon={iconMappings.mapOutlined} menuItemUrl="/roadmap" />
        <NrMenuItem
          menuItemText="Paminga Docs"
          menuItemIcon={iconMappings.help}
          menuItemUrl="https://www.paminga.com/docs"
          menuItemUrlIsExternal
          openExternalLinkInNewTab
        />
        <MenuItem onClick={logout}>
          <ListItemIcon sx={{ color: (theme) => theme.palette.text.primary, mr: -1 }}>
            {iconMappings.logout}
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </MenuItem>
      </CascadingMenu>
    </>
  );
};

export default NavMyAccount;
