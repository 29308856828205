import React, { useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, ListItemIcon, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { AddCircle, Key, Science } from '@mui/icons-material';
import { Link, useHistory } from 'react-router-dom';
import { useCurrentUser } from '@/context/UserContext';
import { useAppContext } from '@/context/AppContext';
import CascadingContext from '../../CascadingContext';

const NrMenuItem = ({
  badge,
  createNewLinkIsExternal,
  createNewUrl,
  featureSlug,
  menuItemText,
  isLabFeature,
  menuItemIcon,
  menuItemUrl,
  menuItemUrlIsExternal,
  menuItemState,
  openExternalLinkInNewTab,
}) => {
  const [menuItemHovered, setMenuItemHovered] = useState(false);
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const { appName } = useAppContext();
  const { rootPopupState } = useContext(CascadingContext);

  if (!rootPopupState) throw new Error('must be used inside a CascadingMenu');

  const handleMouseEnter = () => {
    setMenuItemHovered(!menuItemHovered);
  };

  const handleMouseLeave = () => {
    setMenuItemHovered(!menuItemHovered);
  };

  const handleClick = useCallback(
    (event) => {
      rootPopupState.close(event);
      setMenuItemHovered(false);

      // prevents default browser behavior from kicking in alongside what we make it do below
      // avoid for example a duplicate new tab in Chrome if menuItemUrlIsExternal && openExternalLinkInNewTab.
      event.preventDefault();

      if (menuItemUrlIsExternal) {
        if (openExternalLinkInNewTab) {
          window.open(menuItemUrl, '_blank');
        } else {
          window.location.href = menuItemUrl;
        }
      } else {
        history.push(menuItemUrl);
      }
    },
    [menuItemUrlIsExternal, menuItemUrl, openExternalLinkInNewTab, history, rootPopupState]
  );

  // handleIconClick handles clicks on the "Create New" icon (<AddCircle/>), which is only rendered if the `createNewUrl` prop is not null.
  const handleIconClick = (event) => {
    event.stopPropagation(); // We don't want a click on a "Create New" to propagate to the underlying <MenuItem/>.
    event.preventDefault();
    rootPopupState.close(event);
    setMenuItemHovered(false);
    createNewLinkIsExternal ? window.open(createNewUrl, '_blank') : history.push(createNewUrl);
  };

  const [pathname, search] = menuItemUrl.split('?');

  const featureInclusionType = currentUser.product?.package?.features.find((feature) => feature.slug === featureSlug)
    ?.pivot?.inclusion_type;

  // see if it's included in the customer's package if any.
  // The featureInclusionType may be undefined (no package/feature set) or not explicitly excluded by their package.
  const isFeatureIncluded = typeof featureInclusionType === 'undefined' || !(featureInclusionType === 'NOT_INCLUDED');

  let finalBadge;
  if (badge) {
    finalBadge = React.cloneElement(badge, {
      hovered: menuItemHovered,
      onClick: () => {
        handleClick();
      },
    });
  }

  return (
    <MenuItem
      component={Link}
      to={{
        pathname,
        search,
        state: menuItemState,
      }}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      target={menuItemUrlIsExternal ? '_blank' : '_self'}
      sx={{
        minWidth: '170px',
        ':visited': {
          color: (theme) => theme.palette.text.primary,
        },
      }}
    >
      <ListItemIcon sx={{ color: (theme) => theme.palette.text.primary, mr: -1 }}>{menuItemIcon}</ListItemIcon>
      <ListItemText
        primary={
          <Stack direction="row" alignItems="center" spacing="6px" useFlexGap>
            {menuItemText}
            {finalBadge}
            {appName === 'Paminga' && featureInclusionType === 'NOT_INCLUDED' ? (
              <Key sx={{ color: 'text.disabled', transform: 'rotate(-30deg)' }} />
            ) : null}
          </Stack>
        }
        secondary={
          isLabFeature ? (
            <Stack direction="row" alignItems="center">
              <Typography>Goldilocks Labs</Typography>
              <Science />
            </Stack>
          ) : null
        }
      />
      {createNewUrl ? (
        <Box
          ml={2}
          sx={{ opacity: menuItemHovered ? 1 : 0, lineHeight: 0.5 }}
          data-testid={`menuItemCreateNew-${menuItemText.replace(/\s/g, '-').toLowerCase()}`}
        >
          {isFeatureIncluded ? <AddCircle fontSize="medium" onClick={handleIconClick} /> : null}
        </Box>
      ) : null}
    </MenuItem>
  );
};
NrMenuItem.defaultProps = {
  badge: null,
  createNewLinkIsExternal: false,
  createNewUrl: null,
  featureSlug: null,
  menuItemIcon: null,
  menuItemUrlIsExternal: false,
  menuItemState: null,
  isLabFeature: false,
  openExternalLinkInNewTab: true,
};
NrMenuItem.propTypes = {
  badge: PropTypes.node,
  createNewUrl: PropTypes.string,
  createNewLinkIsExternal: PropTypes.bool,
  featureSlug: PropTypes.string,
  isLabFeature: PropTypes.bool,
  menuItemIcon: PropTypes.node,
  menuItemText: PropTypes.string.isRequired,
  menuItemUrl: PropTypes.string.isRequired,
  menuItemUrlIsExternal: PropTypes.bool,
  menuItemState: PropTypes.object,
  openExternalLinkInNewTab: PropTypes.bool,
};

export default NrMenuItem;
