import React from 'react';
import { Stack, Typography } from '@mui/material';

const LabelBadge = ({
  backgroundColor,
  icon,
  iconPosition,
  label,
}: {
  backgroundColor?: string;
  icon?: React.ReactNode;
  iconPosition?: 'LEFT' | 'RIGHT';
  label?: string;
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        color: '#fff',
        backgroundColor,
        fontWeight: 600,
        px: '6px',
        py: '3px',
        borderRadius: 2,
      }}
    >
      {icon && iconPosition === 'LEFT' ? icon : null}
      <Typography variant="body2">{label}</Typography>
      {icon && iconPosition === 'RIGHT' ? icon : null}
    </Stack>
  );
};

LabelBadge.defaultProps = {
  backgroundColor: '#1F406E',
  icon: null,
  iconPosition: null,
  label: 'New!',
};

export default LabelBadge;
