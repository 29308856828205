import React, { useEffect } from 'react';
import { Box, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import { FormatListBulleted, Help, MoreVert } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useDimensions from 'react-cool-dimensions';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useAppContext } from '@/context/AppContext';
import Search from '@/components/Search';
import NavSales from '@/components/Navigation/TopNav/Sales';
import DocsDialog from '@/apps/Goldilocks/components/DocsDialog';
import FeatureReleaseDialog from '@/apps/Goldilocks/components/FeatureReleaseDialog';
import NavMarketingCenter from '../TopNav/MarketingCenter';
import NavContactsAccounts from '../TopNav/ContactsAndAccounts';
import NavReporting from '../TopNav/Reporting';
import NavRecentItems from '../TopNav/RecentItems';
import NavMyAccount from '../TopNav/MyAccount';
import NotificationBadge from './NotificationBadge';
import NotificationBanner from './NotificationBanner';

const NrAppBar = () => {
  const { leftNavDisabled, leftNavExpanded, handleLeftNavToggle, setHeaderHeight } = useAppContext();
  const nrTheme = useTheme();
  const { observe, height: headerHeight } = useDimensions();

  useEffect(() => {
    setHeaderHeight(headerHeight);
  }, [headerHeight, setHeaderHeight]);

  const DynamicIcon = leftNavExpanded ? MoreVert : FormatListBulleted;
  const leftNavExpansionText = leftNavExpanded ? 'Collapse Sidebar' : 'Expand Sidebar';

  const transition = 'all 300ms ease 0s, opacity 300ms ease 0s';

  const useStyles = makeStyles(() => ({
    show: {
      opacity: 1,
      transition,
    },
    hide: {
      opacity: 0,
      transform: 'translate3d(-15px, 0, 0)',
      transition,
      display: 'none',
    },
  }));

  const classes = useStyles();
  const showHideLeftNavItems = clsx({
    [classes.show]: leftNavExpanded,
    [classes.hide]: !leftNavExpanded,
  });

  return (
    <Box
      ref={observe}
      position="fixed"
      sx={{
        width: '100%',
        minWidth: '1019px',
        transition: 'all 200ms ease-in-out',
        backgroundColor: nrTheme.palette.background.default,
        borderBottom: '1px solid',
        borderBottomColor: nrTheme.palette.borderColor,
        zIndex: (theme) => theme.zIndex.drawer - 1,
      }}
    >
      <NotificationBanner />
      <Grid container wrap="nowrap" direction="row" justifyContent="space-between" alignItems="center" py="4px" px={2}>
        <Grid item>
          <Stack direction="row" spacing={{ xs: 0, sm: 0, md: 0, lg: 2 }}>
            {/* Icon to Expand/Collapse Left Nav */}
            <Tooltip title={leftNavExpansionText} arrow placement="bottom" enterDelay={700}>
              <IconButton
                disabled={leftNavDisabled}
                aria-label="expand/collapse sidebar"
                edge="start"
                sx={{ color: nrTheme.palette.brand.techBlue, ml: { xs: 1, sm: 1, md: 1, lg: 0 } }}
                onClick={handleLeftNavToggle}
              >
                <DynamicIcon size="small" />
              </IconButton>
            </Tooltip>

            {/* NR Logo */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Link to="/" style={{ lineHeight: '0.6rem' }}>
                <Stack direction="row" spacing={0} alignItems="center">
                  <img src={nrTheme.navigation.appBadge} alt="" width={28} />
                  <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'inline-block' }, pt: '3px' }}>
                    <img
                      className={showHideLeftNavItems}
                      src={nrTheme.navigation.logoText}
                      alt=""
                      width={94}
                      style={{ marginLeft: `8px` }}
                    />
                  </Box>
                </Stack>
              </Link>
            </Box>
            <NavSales />
            <NavMarketingCenter />
            <NavContactsAccounts />
            <NavReporting />
            <NavRecentItems />
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={{ md: 0, lg: 1 }} alignItems="center" useFlexGap>
            <Search />
            <Tooltip title="Notifications" enterDelay={700} arrow>
              <IconButton
                size="medium"
                edge="end"
                sx={{
                  '&:hover': {
                    backgroundColor: 'background.offset',
                  },
                }}
              >
                <NotificationBadge />
              </IconButton>
            </Tooltip>

            <FeatureReleaseDialog />

            <DocsDialog
              url="https://www.paminga.com/docs/"
              tooltipTitle="Open Paminga Docs"
              utmContent="Top Nav Bar"
              color="palette.techBlue"
              customIcon={
                <IconButton
                  size="medium"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'background.offset',
                    },
                  }}
                >
                  <Help />
                </IconButton>
              }
            />

            <NavMyAccount />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NrAppBar;
