import React, { useEffect, useState } from 'react';
import { usePopupState, bindMenu } from 'material-ui-popup-state/hooks';
import Menu from 'material-ui-popup-state/HoverMenu';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import update from 'immutability-helper';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '@/utilities/localstorage';
import TopNavItem from '@/components/Navigation/TopNav/TopNavItem';
import {
  navItemsContactsAccounts,
  navItemsMarketingCenter,
  navItemsAccountSettings,
  navItemsPersonalSettings,
  iconMappings,
  navItemsSalesforceIntegration,
  navItemsSugarCrmIntegration,
  navItemsDynamicsIntegration,
  recentItemsBillingSubTabs,
  recentItemsAccountSetup,
  recentItemsDynamicsIntegration,
  recentItemsSalesforceIntegration,
  recentItemsSugarCrmIntegration,
  recentItemsCustomFields,
  recentItemsIntegrations,
  navItemsCrmIntegration,
  leftNavItemsSocialPosting,
  recentItemsSalesforceActions,
} from '../../constants';

const NavRecentItems = () => {
  const [newItem, setNewItem] = useState(null);
  const [recentItems, setRecentItems] = useState(getLocalStorageWithExpiry('recentNavItems') || []);
  const popupState = usePopupState({ variant: 'popover', popupId: 'navRecentItems' });
  const location = useLocation();

  useEffect(() => {
    let item = {};
    const newRecentItem = {};

    // Retrieve that object whose params tell us the page name, URL, and icon for the route the user has visited.
    if (location.pathname === '/') {
      // Leave our `item` object empty if the path is '/'. Because we redirect '/' to '/home', it causes 2 recent items to show up in the nav if we don't do this.
    } else if (location.pathname === '/home') {
      item.location = location;
      item.label = 'Home';
      item.icon = 'home';
    } else if (location.pathname === '/account-setup') {
      item.location = location;
      item.label = 'Account Setup';
      item.icon = 'accountSetup';
    } else if (location.pathname === '/login') {
      // Prevent login from showing up a recent item. (Leave our `item` object empty if the path is '/login')
    } else if (location.pathname === '/marketing-center/actions') {
      item = {
        url: '/marketing-center/actions',
        label: 'Actions',
        icon: 'directionsRun',
      };
    } else if (location.pathname === '/marketing-center/actions/create-tasks') {
      item = {
        url: '/marketing-center/actions/create-tasks',
        label: 'Create CRM Tasks',
        icon: 'directionsRun',
      };
    } else if (location.pathname === '/marketing-center/automations' && location.search === '?type=1') {
      item = {
        url: '/marketing-center/automations?type=1',
        label: 'Workflows',
        icon: 'deviceHub',
      };
    } else if (location.pathname === '/marketing-center/automations' && location.search === '?type=2') {
      item = {
        url: '/marketing-center/automations?type=2',
        label: 'Drip Series',
        icon: 'deviceHub',
      };
    } else if (location.pathname === '/marketing-center/automations' && location.search === '?type=3') {
      item = {
        url: '/marketing-center/automations?type=3',
        label: 'A/B Email Tests',
        icon: 'deviceHub',
      };
    } else if (location.pathname === '/marketing-center/automations') {
      item = {
        url: '/marketing-center/automations',
        label: 'All Automations',
        icon: 'deviceHub',
      };
    } else if (location.pathname === '/marketing-center/emails/email-heatmap') {
      item = {
        url: '/marketing-center/emails/email-heatmap',
        label: 'Email Heatmap',
        icon: 'map',
      };
    } else if (location.pathname === '/marketing-center/email-templates') {
      item = {
        url: '/marketing-center/email-templates',
        label: 'Email Templates',
        icon: 'mailOutline',
      };
    } else if (location.pathname === '/marketing-center/emails/email-preferences') {
      item = {
        url: '/marketing-center/emails/email-preferences',
        label: 'Test Email Recipients',
        icon: 'settings',
      };
    } else if (location.pathname === '/marketing-center/emails/dashboard') {
      item = {
        url: '/marketing-center/emails/dashboard',
        label: 'Email Performance',
        icon: 'equalizer',
      };
    } else if (location.pathname === '/marketing-center/emails/send') {
      location.search = ''; // Prevent multiple recent items based on email ID.
      item = {
        url: '/marketing-center/emails/send',
        label: 'Send One-Off Email',
        icon: 'send',
      };
    } else if (location.pathname === '/reporting/reports/alerts') {
      item = {
        url: '/reporting/reports/alerts',
        label: 'Alerts',
        icon: 'announcement',
      };
    } else if (location.pathname === '/reporting/reports/scheduled-reports') {
      item = {
        url: '/reporting/reports/scheduled-reports',
        label: 'Reports',
        icon: 'subtitles',
      };
    } else if (location.pathname === '/marketing-center/mapped-forms/conversions') {
      item = {
        url: '/marketing-center/mapped-forms/conversions',
        label: 'TBD',
        icon: 'subtitles',
      };
    } else if (location.pathname === '/marketing-center/segments') {
      item = {
        url: '/marketing-center/segments',
        label: 'Global Segments',
        icon: 'segment',
      };
    } else if (location.pathname === '/marketing-center/marketing-campaigns') {
      item = {
        url: '/marketing-center/marketing-campaigns',
        label: 'Marketing Campaigns',
        icon: 'campaign',
      };
    } else if (location.pathname === '/reporting/dashboards') {
      item = {
        url: '/reporting/dashboards',
        label: 'Dashboards',
        icon: 'equalizer',
      };
    } else if (location.pathname === '/reporting/dashboards/attribution') {
      item = {
        url: '/reporting/dashboards/attribution',
        label: 'Attribution Dashboard',
        icon: 'attribution',
      };
    } else if (location.pathname === '/reporting/dashboards/automation-comparison') {
      item = {
        url: '/reporting/dashboards/automation-comparison',
        label: 'Automation Comparison Dashboard',
        icon: 'compare',
      };
    } else if (location.pathname === '/reporting/dashboards/automation-overview') {
      item = {
        url: '/reporting/dashboards/automation-overview',
        label: 'Automation Overview Dashboard',
        icon: 'article',
      };
    } else if (location.pathname === '/reporting/dashboards/automation-performance') {
      item = {
        url: '/reporting/dashboards/automation-performance',
        label: 'Automation Performance Dashboard',
        icon: 'timeline',
      };
    } else if (location.pathname === '/reporting/dashboards/bounce-reasons') {
      item = {
        url: '/reporting/dashboards/bounce-reasons',
        label: 'Bounce Reasons Dashboard',
        icon: 'cancelScheduleSend',
      };
    } else if (location.pathname === '/reporting/dashboards/contacts') {
      item = {
        url: '/reporting/dashboards/contacts',
        label: 'Contacts Dashboard',
        icon: 'group',
      };
    } else if (location.pathname === '/reporting/dashboards/conversionsiframe') {
      item = {
        url: '/reporting/dashboards/conversionsiframe',
        label: 'Form Submissions Dashboard',
        icon: 'sync',
      };
    } else if (location.pathname === '/marketing-center/form-submissions') {
      item = {
        url: '/marketing-center/form-submissions',
        label: 'Form Submissions',
        icon: 'assignment',
      };
    } else if (location.pathname === '/reporting/dashboards/downloads') {
      item = {
        url: '/reporting/dashboards/downloads',
        label: 'Downloads Dashboard',
        icon: 'cloudDownload',
      };
    } else if (location.pathname === '/reporting/dashboards/new-downloads') {
      item = {
        url: '/reporting/dashboards/new-downloads',
        label: 'New Downloads Dashboard',
        icon: 'cloudDownload',
      };
    } else if (location.pathname === '/reporting/dashboards/email-clients') {
      item = {
        url: '/reporting/dashboards/email-clients',
        label: 'Email Clients Dashboard',
        icon: 'devicesOther',
      };
    } else if (location.pathname === '/reporting/dashboards/email-engagement') {
      item = {
        url: '/reporting/dashboards/email-engagement',
        label: 'Email Engagement Dashboard',
        icon: 'email',
      };
    } else if (location.pathname === '/reporting/dashboards/form-submissions') {
      item = {
        url: '/reporting/dashboards/form-submissions',
        label: 'Form Submissions Dashboard',
        icon: 'sync',
      };
    } else if (location.pathname === '/sales/leads') {
      item = {
        url: '/sales/leads',
        label: 'Leads',
        icon: 'lead',
      };
    } else if (location.pathname === '/sales/opportunities') {
      item = {
        url: '/sales/opportunities',
        label: 'Opportunities',
        icon: 'opportunities',
      };
    } else if (location.pathname === '/sales/tasks') {
      item = {
        url: '/sales/tasks',
        label: 'Tasks',
        icon: 'taskAlt',
      };
    } else if (location.pathname === '/reporting/dashboards/marketing-campaign') {
      location.search = ''; // Prevent multiple recent items based on list ID.
      item = {
        url: '/reporting/dashboards/marketing-campaign',
        label: 'Marketing Campaign Dashboard',
        icon: 'campaign',
      };
    } else if (location.pathname === '/reporting/dashboards/marketing-contribution') {
      location.search = ''; // Prevent multiple recent items based on list ID.
      item = {
        url: '/reporting/dashboards/marketing-contribution',
        label: 'Marketing Contribution Dashboard',
        icon: 'campaign',
      };
    } else if (location.pathname === '/marketing-list/dashboard') {
      location.search = ''; // Prevent multiple recent items based on list ID.
      item = {
        url: '/marketing-list/dashboard',
        label: 'Marketing List Dashboard',
        icon: 'listIcon',
      };
    } else if (location.pathname === '/reporting/dashboards/one-off-emails') {
      item = {
        url: '/reporting/dashboards/one-off-emails',
        label: 'One-Off Emails Dashboard',
        icon: 'contactMail',
      };
    } else if (location.pathname === '/subscription-list/dashboard') {
      location.search = ''; // Prevent multiple recent items based on list ID.
      item = {
        url: '/subscription-list/dashboard',
        label: 'Subscription List Dashboard',
        icon: 'listIcon',
      };
    } else if (location.pathname === '/reporting/dashboards/pages') {
      item = {
        url: '/reporting/dashboards/pages',
        label: 'Popular Web Pages Dashboard',
        icon: 'web',
      };
    } else if (location.pathname === '/reporting/dashboards/traffic-sources') {
      item = {
        url: '/reporting/dashboards/traffic-sources',
        label: 'Traffic Sources Dashboard',
        icon: 'share',
      };
    } else if (location.pathname === '/reporting/dashboards/website-visitors') {
      item = {
        url: '/reporting/dashboards/website-visitors',
        label: 'Website Visitors Dashboard',
        icon: 'people',
      };
    } else if (location.pathname === '/contacts/import') {
      item = {
        url: '/contacts/import',
        label: 'Import Contacts',
        icon: 'cloudUpload',
      };
    } else if (location.pathname === '/contacts/bounced') {
      item = {
        url: '/contacts/bounced',
        label: 'Bounced Contacts',
        icon: 'bounced',
      };
    } else if (location.pathname === '/contacts/unsubscribed') {
      item = {
        url: '/contacts/unsubscribed',
        label: 'Unsubscribed Contacts',
        icon: 'unsubscribed',
      };
    } else if (location.pathname === '/contact-imports') {
      item = {
        url: '/contact-imports',
        label: 'Contact Import History',
        icon: 'cloudDone',
      };
    } else if (location.pathname === '/contacts/export-history') {
      item = {
        url: '/contacts/export-history',
        label: 'Contact Export History',
        icon: 'cloudDownload',
      };
    } else if (location.pathname === '/reporting/reports/download-center') {
      item = {
        url: '/reporting/reports/download-center',
        label: 'Download Center',
        icon: 'cloudDownload',
      };
    } else if (location.pathname === '/marketing-center/social/accounts') {
      item = {
        url: '/marketing-center/social/accounts',
        label: 'Social Accounts',
        icon: 'share',
      };
    } else if (location.pathname === '/marketing-center/social/posts/create') {
      item = {
        url: '/marketing-center/social/posts/create',
        label: 'Schedule Social Posts',
        icon: 'accessTime',
      };
    } else if (location.pathname === '/marketing-center/social/calendar') {
      item = {
        url: '/marketing-center/social/calendar',
        label: 'Social Posts - Calendar View',
        icon: 'calendar',
      };
    } else if (location.pathname === '/marketing-center/social/list-view') {
      item = {
        url: '/marketing-center/social/list-view',
        label: 'Social Posts - List View',
        icon: 'listIcon',
      };
    } else if (location.pathname === '/marketing-center/url-builder') {
      item = {
        url: '/marketing-center/url-builder',
        label: 'URL Builder & Shortener',
        icon: 'language',
      };
    } else if (location.pathname === '/roadmap') {
      item = {
        url: '/roadmap',
        label: 'Product Roadmap',
        icon: 'map',
      };
    } else if (
      location.pathname.startsWith('/accounts') ||
      location.pathname.startsWith('/contacts') ||
      location.pathname.startsWith('/custom-objects')
    ) {
      item = navItemsContactsAccounts.find(({ url }) => location.pathname.includes(url));
    } else if (location.pathname.includes('/marketing-center/brand-kits/create')) {
      item = null;
    } else if (location.pathname.includes('/marketing-center/next-gen-landing-pages/create')) {
      item = null;
    } else if (location.pathname.startsWith('/marketing-center')) {
      item = navItemsMarketingCenter.find(({ url }) => location.pathname.includes(url));
    } else if (location.pathname.startsWith('/settings/account/setup')) {
      item = recentItemsAccountSetup.find(({ url }) => `${location.pathname}${location.hash}`.includes(url));
    } else if (location.pathname.startsWith('/settings/personal')) {
      item = navItemsPersonalSettings.find(({ url }) => location.pathname.includes(url));
      // this ('/settings/account/billing') must precede '/settings/account' or it won't be evaluated due to "else if".
    } else if (location.pathname.startsWith('/settings/account/billing')) {
      item = recentItemsBillingSubTabs.find(({ url }) => `${location.pathname}${location.hash}`.includes(url));
    } else if (location.pathname.startsWith('/settings/account/custom-fields')) {
      item = recentItemsCustomFields.find(({ url }) => `${location.pathname}${location.hash}`.includes(url));
    } else if (location.pathname.startsWith('/settings/account')) {
      item = navItemsAccountSettings.find(({ url }) => location.pathname.includes(url));
    } else if (location.pathname.startsWith('/setup/account/integrations/salesforce/field-mappings')) {
      item = recentItemsSalesforceIntegration.find(({ url }) => `${location.pathname}${location.hash}`.includes(url));
      // this is a hack to dedupe lead mappings because it's got two urls that can load it
      if (location.hash === '') {
        location.hash = '#account';
      }
    } else if (location.pathname.startsWith('/setup/account/integrations/salesforce/actions')) {
      item = recentItemsSalesforceActions.find(({ url }) => `${location.pathname}${location.hash}`.includes(url));
      // this is a hack to dedupe lead mappings because it's got two urls that can load it
      if (location.hash === '') {
        location.hash = '#contact';
      }
    } else if (location.pathname.startsWith('/setup/account/integrations/salesforce')) {
      item = navItemsSalesforceIntegration.find(({ url }) => location.pathname.includes(url));
    } else if (location.pathname.startsWith('/setup/account/integrations/crm')) {
      item = navItemsCrmIntegration.find(({ url }) => location.pathname.includes(url));
    } else if (location.pathname.startsWith('/setup/account/integrations/sugarcrm/field-mappings')) {
      item = recentItemsSugarCrmIntegration.find(({ url }) => `${location.pathname}${location.hash}`.includes(url));
    } else if (location.pathname.startsWith('/setup/account/integrations/sugarcrm')) {
      item = navItemsSugarCrmIntegration.find(({ url }) => location.pathname.includes(url));
    } else if (location.pathname.startsWith('/setup/account/integrations/dynamics/field-mappings')) {
      item = recentItemsDynamicsIntegration.find(({ url }) => `${location.pathname}${location.hash}`.includes(url));
    } else if (location.pathname.startsWith('/setup/account/integrations/dynamics')) {
      item = navItemsDynamicsIntegration.find(({ url }) => location.pathname.includes(url));
    } else if (location.pathname.startsWith('/setup/account/integrations')) {
      item = recentItemsIntegrations.find(({ url }) => location.pathname.includes(url));
    } else if (location.pathname.startsWith('/notifications')) {
      item = {
        label: 'Notifications',
        location,
        icon: 'notifications',
      };
    } else if (location.pathname.startsWith('/marketing-center/social/accounts')) {
      item = leftNavItemsSocialPosting.find(({ url }) => location.pathname.includes(url));
    } else {
      item.label = 'TBD';
      item.location = location;
      item.icon = null;
    }

    if (item && typeof item !== 'undefined' && item.label !== 'TBD') {
      newRecentItem.label = item.label;
      newRecentItem.location = location;
      newRecentItem.icon = item.icon;
    }

    let editingObject = false;
    if (
      location.pathname.includes('createEdit') ||
      location.hash.includes('createEdit') ||
      location.search.includes('=')
    ) {
      // Prevent adding entry to RecentItems when our user is creating/editing an object in a <DrawerRight/>.
      // This was decided because a) this file is already ugly, b) it's not critical for Phase 1, and c) it would get worse quickly if we have to update object names in RecentItems when they are edited and have to remove objects from RecentItems when they are deleted.
      // We will revisit this some time down the road.
      editingObject = true;
    }

    if (newRecentItem.location && !editingObject) {
      setNewItem(newRecentItem);
    }
  }, [location]);

  if (newItem) {
    // Add our new item "to the top" of the recentItems array such that our array is ordered from most to least recently viewed.
    const newArrRecents = update(recentItems, {
      $unshift: [newItem],
    });

    // If this same route was visited within the last 10 recent items, the older one will be a duplicate. No point in that, so kick it out of the array.
    const uniqueRecents = [];
    const map = new Map();
    for (const item of newArrRecents) {
      if (!map.has(`${item.location.pathname}${item.location.search}${item.location.hash}`)) {
        map.set(`${item.location.pathname}${item.location.search}${item.location.hash}`, true);
        uniqueRecents.push({
          label: item.label,
          location: item.location,
          icon: item.icon,
        });
      }
    }
    // Remove items older than the 10th most recent item.
    if (uniqueRecents.length > 10) {
      uniqueRecents.pop();
    }

    setRecentItems(uniqueRecents); // Store our array of recentItems in state.
    setLocalStorageWithExpiry('recentNavItems', uniqueRecents, 7 * 60 * 60 * 1000);
    setNewItem(null); // Set newItem back to null so this fine routine operates properly when our user visits another route.
  }

  /**
   * mapRecentItems
   * Creates a <MenuItem/> for each element in our recentItems array.
   */
  const mapRecentItems = (items) => {
    return items.map((item) => {
      return (
        <MenuItem
          component={Link}
          to={{
            pathname: item.location.pathname ? item.location.pathname : '/',
            search: item.location.search,
            hash: item.location.hash,
          }}
          onClick={popupState.close}
          key={`${item.location.pathname}${item.location.search}${item.location.hash}`}
          sx={{
            ':visited': {
              color: (theme) => theme.palette.text.primary,
            },
          }}
        >
          <ListItemIcon sx={{ color: (theme) => theme.palette.text.primary, mr: -1 }}>
            {iconMappings[item.icon]}
          </ListItemIcon>
          <ListItemText primary={item.label} />
        </MenuItem>
      );
    });
  };

  const recentItemsRender = mapRecentItems(recentItems);

  return (
    <>
      <TopNavItem label="Recent Items" popupState={popupState} />

      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        {...bindMenu(popupState)}
      >
        {recentItemsRender}
      </Menu>
    </>
  );
};

export default NavRecentItems;
