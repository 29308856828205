import React, { ReactNode } from 'react';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

const NrButton = ({
  type = 'primary',
  variant = 'contained',
  size = 'small',
  onClick = () => {},
  disabled = false,
  startIcon = null,
  endIcon = null,
  fullWidth = false,
  disableFocusRipple = false,
  disableElevation = false,
  dataTestId = null,
  children = '',
  sx = {},
  buttonType = 'button',
  isLoading = false,
  ...rest
}: {
  type?: 'primary' | 'secondary' | 'tangerine';
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  disabled?: boolean;
  startIcon?: ReactNode | null;
  endIcon?: ReactNode | null;
  fullWidth?: boolean;
  disableFocusRipple?: boolean;
  disableElevation?: boolean;
  dataTestId?: string | null;
  children?: any;
  sx?: any;
  buttonType?: string;
  isLoading?: boolean;
}) => {
  const theme = useTheme();
  let disableElevationDefault = disableElevation;
  if (type === 'secondary') {
    disableElevationDefault = true;
  }

  let color;
  let hoverColor;
  let backgroundColor;
  let backgroundHoverColor;
  let borderColor;
  let borderHoverColor;
  let fontFamily;
  let fontSize;
  let borderWidth;
  let borderRadius;

  const styleOverrides: any = theme.components?.MuiButton?.styleOverrides;

  switch (type) {
    case 'primary':
      color = 'buttons.primary.color';
      hoverColor = 'buttons.primary.hoverColor';
      backgroundColor = 'buttons.primary.backgroundColor';
      backgroundHoverColor = 'buttons.primary.backgroundHoverColor';
      borderColor = 'buttons.primary.borderColor';
      borderHoverColor = 'buttons.primary.borderHoverColor';
      fontFamily = styleOverrides?.primary?.fontFamily;
      fontSize = styleOverrides?.primary?.fontSize;
      borderWidth = styleOverrides?.primary?.borderWidth;
      borderRadius = styleOverrides?.primary?.borderRadius;
      break;
    case 'secondary':
      color = 'buttons.secondary.color';
      hoverColor = 'buttons.secondary.hoverColor';
      backgroundColor = 'buttons.secondary.backgroundColor';
      backgroundHoverColor = 'buttons.secondary.backgroundHoverColor';
      borderColor = 'buttons.secondary.borderColor';
      borderHoverColor = 'buttons.secondary.borderHoverColor';
      fontFamily = styleOverrides?.secondary?.fontFamily;
      fontSize = styleOverrides?.secondary?.fontSize;
      borderWidth = styleOverrides?.secondary?.borderWidth;
      borderRadius = styleOverrides?.secondary?.borderRadius;
      break;
    case 'tangerine':
      color = '#fff';
      hoverColor = '#fff';
      backgroundColor = 'buttons.tangerine.backgroundColor';
      backgroundHoverColor = 'buttons.tangerine.backgroundHoverColor';
      borderColor = 'buttons.tangerine.borderColor';
      borderHoverColor = 'buttons.tangerine.borderHoverColor';
      fontFamily = styleOverrides?.tangerine?.fontFamily;
      fontSize = styleOverrides?.tangerine?.fontSize;
      borderWidth = styleOverrides?.tangerine?.borderWidth;
      borderRadius = styleOverrides?.tangerine?.borderRadius;
      break;
    // no default
  }

  return (
    // @ts-ignore
    <LoadingButton
      data-testid={dataTestId}
      loading={isLoading}
      sx={{
        color,
        borderRadius: borderRadius ?? '20px',
        borderWidth: borderWidth ?? 'inherit',
        borderColor,
        backgroundColor,
        fontSize: fontSize ?? { xs: '0.7rem', sm: '0.775rem' },
        fontWeight: 700,
        fontFamily: fontFamily ?? 'inherit',
        letterSpacing: { xs: '0.1rem', sm: '0.12rem' },
        py: { xs: '6px', sm: 1 },
        paddingLeft: { xs: '14px', sm: 3 },
        paddingRight: { xs: '14px', sm: 3 },
        ':hover': {
          color: sx?.hoverColor ?? hoverColor,
          backgroundColor: sx?.backgroundHoverColor ?? backgroundHoverColor,
          borderColor: sx?.borderHoverColor ?? borderHoverColor,
          transform: 'scale(1.02)',
        },
        width: fullWidth ? '100%' : 'fit-content',
        transition: 'all 200ms ease-in-out',
        borderStyle: 'solid',
        ...sx,
      }}
      variant={variant}
      size={size}
      type={buttonType}
      onClick={onClick}
      disabled={disabled}
      disableFocusRipple={disableFocusRipple}
      disableElevation={disableElevationDefault}
      startIcon={startIcon}
      endIcon={endIcon}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};

NrButton.defaultProps = {
  onClick: () => {},
  size: 'small',
  type: 'primary',
  variant: 'contained',
  disabled: false,
  fullWidth: false,
  disableFocusRipple: false,
  disableElevation: false,
  startIcon: null,
  endIcon: null,
  dataTestId: null,
  children: '',
  sx: {},
  buttonType: 'button',
  isLoading: false,
};

export default NrButton;
