import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';
import ConfettiExplosion from 'react-confetti-explosion';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Fade, FormControl, FormLabel, LinearProgress, Rating, Slide, Stack, Typography } from '@mui/material';
import { HookFormTextField } from '@/components/HookFormFields';
import { TransitionBounceIn } from '@/components/Transitions';
import NrLogo from '@/components/NrLogo';
import NrButton from '@/components/NrMaterialOverrides/NrButton';
import { Heading } from '@/components/core';

const IcpCustomerSurvey = () => {
  const [question, setQuestion] = useState(0);
  const [rating, setRating] = useState(0);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const nrTheme = useTheme();

  useEffect(() => {
    document.title = surveyCompleted ? 'Thank you for your feedback!' : `We'd love your feedback!`;
  });

  const questionOneValidationSchema =
    question === 1
      ? {
          descriptiveWord1: Yup.string().required('Please give us one word'),
          descriptiveWord2: Yup.string().required('Please give us another word'),
          descriptiveWord3: Yup.string().required('Please provide one last word'),
        }
      : null;

  const questionTwoValidationSchema =
    question === 2
      ? {
          whyChooseToBeCustomer: Yup.string().required('Please let us know why you choose Net-Results'),
        }
      : null;

  const questionFourValidationSchema =
    question === 4
      ? {
          howStayUpToDate: Yup.string().required('Please let us know how you stay up to date'),
        }
      : null;

  const validationSchema = Yup.object().shape({
    ...questionOneValidationSchema,
    ...questionTwoValidationSchema,
    ...questionFourValidationSchema,
  });

  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues: {
      descriptiveWord1: '',
      descriptiveWord2: '',
      descriptiveWord3: '',
      whyChooseToBeCustomer: '',
      iCareToShare: '',
      howStayUpToDate: '',
      whereToFindExperiencedLeaders: '',
      rating: 0,
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { isSubmitting },
    getValues,
    handleSubmit,
    reset,
    setValue,
    trigger,
  } = formMethods;

  const onSubmit = async (data: any) => {
    // @ts-ignore
    await fetch(`${import.meta.env.VITE_APP_LARAVEL_ENDPOINT}/api/icp-survey`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ data: { ...data } }),
    });
    setSurveyCompleted(true);
    reset();
  };

  const mayMoveToNextQuestion = () => {
    switch (question) {
      case 0:
        return true;
      case 1:
        return (
          getValues('descriptiveWord1') !== '' &&
          getValues('descriptiveWord2') !== '' &&
          getValues('descriptiveWord3') !== ''
        );
      case 2:
        return getValues('whyChooseToBeCustomer') !== '';
      case 4:
        return getValues('howStayUpToDate') !== '';
      default:
        return true;
    }
  };

  const handleNextClick = () => {
    if (question > 0) {
      trigger();
    }
    if (mayMoveToNextQuestion()) {
      setQuestion(question + 1);
    }
  };

  const handlePreviousClick = () => {
    setQuestion(question - 1);
  };

  return (
    <Box p={2}>
      <Stack direction="column" spacing={3} sx={{ width: 700, mx: 'auto' }}>
        <Box sx={{ textAlign: 'center' }} pb={2}>
          <NrLogo />
        </Box>
        {/* Introduction */}
        {question === 0 ? (
          <Fade in appear timeout={1750}>
            <Stack direction="column" spacing={4} alignItems="center">
              <Box sx={{ textAlign: 'center' }}>
                {/* @ts-ignore */}
                <Heading label="Ideal Customer Survey" paddingLeft={0} />
                <Typography variant="h6" sx={{ letterSpacing: '0.08rem' }}>
                  (It&apos;s brief, <strong>only 6 questions</strong>!)
                </Typography>

                <Typography pt={3} sx={{ fontSize: '1.08em', lineHeight: '1.7em' }}>
                  We appreciate you taking the time to help us out. We find you to be an great fit for our &quot;Ideal
                  Customer Profile&quot;, and place great value on your opinions.
                </Typography>
              </Box>
              <Box
                sx={{
                  mb: 3,
                  height: 190,
                  width: '100%',
                  textAlign: 'center',
                  borderRadius: '8px',
                  backgroundImage:
                    'url(https://res.cloudinary.com/net-results/image/upload/v1698788830/SPA/undraw_customer_survey.svg)',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center bottom',
                }}
              />

              <Box sx={{ pt: 4, textAlign: 'center' }}>
                <NrButton onClick={handleNextClick}>Begin!</NrButton>
              </Box>
            </Stack>
          </Fade>
        ) : null}

        {!surveyCompleted ? (
          <FormProvider {...formMethods}>
            <form>
              <Stack direction="column" spacing={3} sx={{ width: 450, mx: 'auto' }}>
                {/* Question #1 */}
                {question === 1 ? (
                  <TransitionBounceIn transitionIn>
                    <FormControl fullWidth sx={{ minHeight: 255 }}>
                      <FormLabel sx={{ pb: 2, color: nrTheme.palette.text.primary, fontSize: '1.08em' }}>
                        What are 3 words or phrases you might use to describe Net-Results?
                      </FormLabel>
                      <Stack direction="column" spacing={2}>
                        <HookFormTextField
                          name="descriptiveWord1"
                          type="text"
                          label="First Word/Phrase"
                          required
                          autoFocus
                        />
                        <HookFormTextField name="descriptiveWord2" type="text" label="Second Word/Phrase" required />
                        <HookFormTextField name="descriptiveWord3" type="text" label="Third Word/Phrase" required />
                      </Stack>
                    </FormControl>
                  </TransitionBounceIn>
                ) : null}

                {/* Question #2 */}
                {question === 2 ? (
                  <Slide direction="left" in mountOnEnter unmountOnExit>
                    <FormControl fullWidth sx={{ minHeight: 255 }}>
                      <FormLabel sx={{ pb: 2, color: nrTheme.palette.text.primary, fontSize: '1.08em' }}>
                        Why do you continue to choose to be a customer of Net-Results?
                      </FormLabel>
                      <HookFormTextField
                        name="whyChooseToBeCustomer"
                        type="text"
                        label="Please Tell Us Why"
                        multiline
                        rows={3}
                        required
                        fullWidth
                        autoFocus
                      />
                    </FormControl>
                  </Slide>
                ) : null}

                {/* Question #3 */}
                {question === 3 ? (
                  <Slide direction="left" in mountOnEnter unmountOnExit>
                    <FormControl fullWidth sx={{ minHeight: 255 }}>
                      <FormLabel sx={{ pb: 2, color: nrTheme.palette.text.primary, fontSize: '1.08em' }}>
                        Anything else you care to share about Net-Results as a company, team, vendor, or business
                        partner?
                      </FormLabel>
                      <HookFormTextField
                        name="iCareToShare"
                        type="text"
                        label="Tell Us More"
                        multiline
                        rows={3}
                        fullWidth
                        autoFocus
                      />
                    </FormControl>
                  </Slide>
                ) : null}

                {/* Question #4 */}
                {question === 4 ? (
                  <Slide direction="left" in mountOnEnter unmountOnExit>
                    <FormControl fullWidth sx={{ minHeight: 255 }}>
                      <FormLabel sx={{ pb: 2, color: nrTheme.palette.text.primary, fontSize: '1.08em' }}>
                        How do you keep up with the latest trends and topics in marketing?
                        <br />
                        <br />
                        Are there specific social media platforms, resources, publications, or communities that you find
                        particularly valuable for staying ahead in the field?
                      </FormLabel>
                      <HookFormTextField
                        name="howStayUpToDate"
                        type="text"
                        label="How do you stay up to date?"
                        multiline
                        rows={3}
                        fullWidth
                        required
                        autoFocus
                      />
                    </FormControl>
                  </Slide>
                ) : null}

                {/* Question #5 */}
                {question === 5 ? (
                  <Slide direction="left" in mountOnEnter unmountOnExit>
                    <FormControl fullWidth sx={{ minHeight: 255 }}>
                      <FormLabel sx={{ pb: 2 }}>
                        Do you have any thoughts or suggestions as to where or how we could reach more experienced
                        leaders like yourself?
                      </FormLabel>
                      <HookFormTextField
                        name="whereToFindExperiencedLeaders"
                        type="text"
                        label="Suggestions"
                        multiline
                        rows={3}
                        fullWidth
                        autoFocus
                      />
                    </FormControl>
                  </Slide>
                ) : null}

                {/* Question #6 */}
                {question === 6 ? (
                  <Slide direction="left" in mountOnEnter unmountOnExit>
                    <Stack direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: 255 }}>
                      <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h6" pb={3}>
                          How likely are you to recommend Net-Results
                          <br />
                          to other marketers?
                        </Typography>
                      </Box>
                      <Rating
                        name="simple-controlled"
                        value={rating}
                        size="large"
                        max={10}
                        onChange={(event, newValue) => {
                          setRating(newValue || 0);
                          setValue('rating', newValue || 0);
                        }}
                      />
                    </Stack>
                  </Slide>
                ) : null}

                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent={() => {
                    switch (question) {
                      case 0:
                        return 'center';
                      case 1:
                        return 'flex-end';
                      default:
                        return 'space-between';
                    }
                  }}
                >
                  {question > 1 ? (
                    <NrButton onClick={handlePreviousClick} type="secondary">
                      Previous
                    </NrButton>
                  ) : null}

                  {question > 0 && question < 6 ? <NrButton onClick={handleNextClick}>Next</NrButton> : null}

                  {question === 6 ? (
                    <Box sx={{ textAlign: 'center', mt: 4 }}>
                      <NrButton disableFocusRipple onClick={handleSubmit(onSubmit)} isLoading={isSubmitting}>
                        Finish!
                      </NrButton>
                    </Box>
                  ) : null}
                </Stack>

                {question > 0 ? (
                  <Stack direction="column" spacing={2} pt={4}>
                    <LinearProgress variant="determinate" value={((question - 1) / 6) * 100} />
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography>Question {question} of 6</Typography>
                    </Box>
                  </Stack>
                ) : null}
              </Stack>
            </form>
          </FormProvider>
        ) : (
          <>
            {/* <ConfettiExplosion force="0.8" duration={3000} particleCount={250} width={1600} /> */}
            <Stack direction="column" spacing={3} alignItems="center">
              <ConfettiExplosion />
              <Typography variant="h2">Thank You!</Typography>
              <Typography variant="h4">Your feedback is greatly appreciated.</Typography>
              <Typography variant="h6">Please let us know if there is anything we can do for you.</Typography>
              <span style={{ fontSize: 34 }}>🥳</span>
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default IcpCustomerSurvey;
