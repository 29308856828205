const shouldSalesFeaturesBeDisplayed = (currentUser) => {
  // Always display "Sales Features" if product_id = 5 || 3113 || 28764 (Paminga Marketing).
  if ([5, 3113, 28764].includes(Number(currentUser.product.id))) {
    return true;
  }

  // Do not display "Sales Features" if product_id has an active CRM integration.
  if (
    currentUser.product.dynamics_integration ||
    currentUser.product.salesforce_integration ||
    currentUser.product.sugar_crm_integration ||
    currentUser.product?.crm_integrations?.length > 0
  ) {
    return false;
  }

  // Otherwise, go ahead and display "Sales Features".
  return true;
};

export default shouldSalesFeaturesBeDisplayed;
