import React from 'react';
import { Controller } from 'react-hook-form';
import Slider from '@/components/Slider';

const HookFormSlider = ({
  suffix,
  step,
  min,
  max,
  icon,
  disabled,
  helperText,
  label,
  name,
  onChange,
  size,
  sx,
  defaultValue,
}: {
  suffix: string;
  step: number;
  min: number;
  max: number;
  icon?: JSX.Element;
  disabled?: boolean;
  helperText?: string;
  label?: string;
  name: string;
  onChange?: any;
  size?: 'small' | 'medium';
  sx?: object;
  defaultValue?: any;
}) => {
  function getValue(fieldValue: any) {
    if (fieldValue) {
      return typeof fieldValue === 'number' ? fieldValue : Number(fieldValue) || 0;
    }
    if (defaultValue) {
      return defaultValue;
    }
    return 0;
  }

  return (
    <Controller
      name={name}
      render={({ field, formState: { isSubmitting } }) => (
        <Slider
          label={label}
          suffix={suffix}
          step={step}
          min={min}
          max={max}
          icon={icon}
          disabled={isSubmitting || disabled}
          helperText={helperText}
          onChange={(value: any) => {
            let numberValue: any = Number(value);
            numberValue = isNaN(numberValue) ? '' : numberValue;
            if (numberValue > max) {
              numberValue = max;
            }

            if (numberValue < min) {
              numberValue = min;
            }

            field.onChange(numberValue);
            onChange(value);
          }}
          size={size}
          sx={{
            ...sx,
            '& .MuiSlider-thumb': {
              color: defaultValue && getValue(field.value) === defaultValue ? 'grey' : 'primary',
            },
            '& .MuiSlider-track': {
              color: defaultValue && getValue(field.value) === defaultValue ? 'grey' : 'primary',
            },
          }}
          defaultValue={field.value ?? defaultValue}
        />
      )}
    />
  );
};

HookFormSlider.defaultProps = {
  disabled: false,
  helperText: '',
  icon: null,
  label: '',
  onChange: () => {},
  size: 'small',
  sx: {},
  defaultValue: null,
};

export default HookFormSlider;
