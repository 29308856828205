import gql from 'graphql-tag';

export const UserQuery = gql`
  query UserQuery($id: ID!) {
    user(id: $id) {
      id
      first_name
      last_name
      email
      title
      work_phone
      mobile_phone
      campaign_approver
    }
  }
`;

export const UsersQuery = gql`
  query UsersQuery(
    $limit: Int!
    $page: Int!
    $sortField: UserOrderByColumns!
    $sortDirection: SortOrder!
    $dynamicConditions: QueryUsersWhereWhereConditions!
  ) {
    users(
      orderBy: [{ column: $sortField, order: $sortDirection }]
      where: $dynamicConditions
      first: $limit
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        email
        first_name
        last_name
        work_phone
        title
        enabled
        campaign_approver
      }
    }
  }
`;

export const UsersForAutocompleteQuery = gql`
  query UsersForAutocompleteQuery(
    $limit: Int!
    $page: Int!
    $sortField: UserOrderByColumns!
    $sortDirection: SortOrder!
    $dynamicConditions: QueryUsersWhereWhereConditions!
  ) {
    users(
      orderBy: [{ column: $sortField, order: $sortDirection }]
      where: $dynamicConditions
      first: $limit
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        label: email
        value: id
      }
    }
  }
`;

export const UsersForSendNotificationAutocompleteQuery = gql`
  query UsersForSendNotificationAutocompleteQuery(
    $limit: Int!
    $page: Int!
    $sortField: UserOrderByColumns!
    $sortDirection: SortOrder!
    $dynamicConditions: QueryUsersWhereWhereConditions!
  ) {
    users(
      orderBy: [{ column: $sortField, order: $sortDirection }]
      where: $dynamicConditions
      first: $limit
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        first_name
        last_name
        email
        mobile_phone
      }
    }
  }
`;

export const CreateUserMutation = gql`
  mutation CreateUserMutation($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      first_name
      last_name
      email
      title
      mobile_phone
      work_phone
      campaign_approver
    }
  }
`;

export const UpdateUserMutation = gql`
  mutation UpdateUserMutation($id: ID!, $input: PatchableUserFields!) {
    updateUser(input: { id: $id, patch: $input }) {
      id
      first_name
      last_name
      email
      title
      work_phone
      campaign_approver
    }
  }
`;

export const DeleteUserMutation = gql`
  mutation DeleteUserMutation($id: ID!) {
    deleteUser(id: $id) {
      email
    }
  }
`;

export const UpdateUserPasswordMutation = gql`
  mutation UpdateUserPasswordMutation($id: ID!, $password: String!) {
    updateUserPassword(input: { id: $id, password: $password }) {
      email
      auth_token
      product {
        session_duration
      }
    }
  }
`;

export const SendNewUserEmail = gql`
  mutation SendNewUserEmail($id: ID!) {
    sendNewUserEmail(id: $id) {
      email
    }
  }
`;

export const MeQuery = gql`
  query QueryMe {
    me {
      id
      first_name
      last_name
      email
      work_phone
      mobile_phone
      title
      pagination_size
      date_format
      time_format
      number_format
      time_zone
      theme
      sidebar_collapsed_state
      campaign_approver
      export_field_preferences
      show_drip_campaign_intro
      show_workflow_intro
      show_abandoned_cart_intro
      primary_segment_id
      default_domain_group_id
      mfa_enabled
      autosave_forms
      product {
        id
        customer_id
        gdpr_default
        utm_tagging_enabled
        minimum_password_length
        password_require_special_chars
        session_duration
        campaign_approval_required
        is_sandbox
        is_main_product
        mfa_enabled_at
        crm_integrations {
          id
          crm
          url
          enabled
        }
        data_enrichment_integrations {
          id
          platform_type
          active
        }
        email_testing_integrations {
          id
          platform_type
          recipient_email
          active
        }
        payment_integrations {
          id
          type
        }
        salesforce_integration {
          id
          sync_enabled
          person_account
        }
        dynamics_integration {
          id
          sync_enabled
        }
        sugar_crm_integration {
          id
          sync_enabled
        }
        standard_objects {
          id
          name
          singular_label
          plural_label
        }
      }
    }
  }
`;

export const SubProductsQuery = gql`
  query SubProductsQuery {
    subProducts {
      product_id
      login_user {
        id
        auth_token
        first_name
        last_name
        email
        work_phone
        mobile_phone
        title
        pagination_size
        date_format
        time_format
        number_format
        time_zone
        theme
        sidebar_collapsed_state
        campaign_approver
        show_drip_campaign_intro
        show_workflow_intro
        show_abandoned_cart_intro
        primary_segment_id
        default_domain_group_id
        product {
          id
          customer_id
          gdpr_default
          utm_tagging_enabled
          minimum_password_length
          password_require_special_chars
          session_duration
          campaign_approval_required
          is_sandbox
          is_main_product
          crm_integrations {
            id
            crm
            url
            enabled
          }
          salesforce_integration {
            id
            sync_enabled
            person_account
          }
          dynamics_integration {
            id
            sync_enabled
          }
          sugar_crm_integration {
            id
            sync_enabled
          }
          standard_objects {
            id
            name
            singular_label
            plural_label
          }
        }
      }
    }
  }
`;

export default UsersQuery;
