import React, { useState, useEffect, useRef, useContext, forwardRef, createContext, memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Popper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import { bindMenu, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { ArrowDownward, ArrowUpward, ImportExport } from '@mui/icons-material';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '@/utilities/localstorage';
import stringUtilities from '@/utilities/String';
import dateTime from '@/utilities/DateTime';
import { useCurrentUser } from '@/context/UserContext';
import formatters from '@/utilities/Format';

const LISTBOX_PADDING = 8; // px

const renderRow = (rowProps) => {
  const { data, index, style, useLabelsAsFonts } = rowProps;
  const { label, extraLabel, value } = data[index][1];

  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (extraLabel) {
    // We have extra information we need to display next to the name.
    return (
      <Typography component="li" {...data[index][0]} noWrap style={inlineStyle} key={value}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Chip
            size="small"
            label={extraLabel.value}
            sx={extraLabel.type === 'date' ? { minWidth: 85 } : null} // 85 is the smallest width to fit any formatted DateTime
          />
          <Typography>{label}</Typography>
        </Stack>
      </Typography>
    );
  }
  // We are sorting on the primary field (most likely name) and we only need to render this field
  return (
    <Typography
      fontFamily={useLabelsAsFonts ? `${label}, Karla, Arial` : undefined}
      component="li"
      {...data[index][0]}
      noWrap
      style={inlineStyle}
      key={value}
    >
      {label}
    </Typography>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

// Adapter for react-window
const ListboxComponent = forwardRef((props, ref) => {
  const { children, ...other } = props;
  const { currentUser } = useCurrentUser();

  function formatExtraLabel(extraLabel) {
    if (extraLabel.type === 'date' && extraLabel.value) {
      // return the formatted date
      return dateTime.formatUtcDate(
        extraLabel.value,
        currentUser?.date_format || 'MM/DD/YYYY',
        currentUser?.time_zone || Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Denver'
      );
    }
    if (extraLabel.type === 'number' && extraLabel.value) {
      return formatters.formatNumber(extraLabel.value);
    }
    if (extraLabel.value !== null && extraLabel.value !== false) {
      return extraLabel.value;
    }
    return 'None';
  }

  const itemData = [];
  children.forEach((item) => {
    if (item[1].extraLabel) {
      // If this item has an ExtraLabel, format it according to formatExtraLabel.
      itemData.push([
        item[0],
        {
          ...item[1],
          extraLabel: { value: formatExtraLabel(item[1].extraLabel), type: item[1].extraLabel.type },
        },
      ]);
    } else {
      itemData.push(item);
    }
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (Object.prototype.hasOwnProperty.call(child, 'group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.array.isRequired,
  useLabelsAsFonts: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const HookFormAutocompleteSortable = memo(
  ({
    autoFocus,
    defaultValue,
    disabled,
    helperText,
    label,
    loading,
    name,
    onChange,
    onInputChange,
    onMouseDown,
    options: initialOptions,
    required,
    localStorageSortPreferenceType,
    sx,
    useLabelsAsFonts,
    width,
  }) => {
    const sortPreferencePopupState = usePopupState({ variant: 'popover' });
    const [autocompletePopupOpen, setAutocompletePopupOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [sortByOptions, setSortByOptions] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const formatDateColumns = ['created_at', 'updated_at', 'launch_date', 'starts_at']; // Add any new DateTime columns here.

    // Will convert the Enum Type to a LocalStorage name
    // ie MARKETING_LISTS -> MarketingListsAutocompleteSortPreference
    const localStorageSortPreferenceName = localStorageSortPreferenceType
      ? `${stringUtilities
          .capitalizeWords(localStorageSortPreferenceType.replaceAll('_', ' '), true)
          .replaceAll(' ', '')}AutocompleteSortPreference`
      : null;

    useEffect(() => {
      if (loading) {
        return;
      }
      if (initialOptions.length === 0) {
        // If no options were passed, this tells us to not sort but also not to render that the autocomplete is loading.
        setSortBy({ key: 'skip' });
        return;
      }

      // Will create an array of options for the Sort Preference Autocomplete from every column for the parent Autocomplete.
      // ie if the data for the parent Autocomplete is of the structure { name: x, updated_at: x, value: id }
      // sortByOptions will equal [ {label: Name, value: name }, { label: Updated At, value: updated_at }]
      if (!sortByOptions) {
        setSortByOptions([
          ...Object.keys(initialOptions[0])
            .filter((key) => key !== 'value')
            .map((option) => {
              return {
                label: stringUtilities.capitalizeWords(option.replaceAll('_', ' ')),
                type: formatDateColumns.includes(option) ? 'date' : typeof initialOptions[0][`${option}`],
                value: option,
              };
            }),
        ]);
      }

      if (sortByOptions) {
        // Get Sort Preference from Local Storage
        // If Local Storage value exists and is a valid Sort By Preference, set it.
        // Otherwise, set sortBy to the first option ascending.
        setSortBy(
          getLocalStorageWithExpiry(localStorageSortPreferenceName) &&
            sortByOptions.some(
              (option) => option.value === getLocalStorageWithExpiry(localStorageSortPreferenceName).key
            )
            ? getLocalStorageWithExpiry(localStorageSortPreferenceName)
            : { key: sortByOptions[0].value, sortByAsc: true }
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialOptions, loading, localStorageSortPreferenceName, sortByOptions]);

    if (loading || !sortBy) {
      return (
        <FormControl variant="outlined" size="small" fullWidth={!width}>
          <InputLabel>{label}</InputLabel>
          <OutlinedInput
            label={label}
            endAdornment={<CircularProgress size={20} />}
            disabled
            sx={{ backgroundColor: 'divider', ...sx, width }}
          />
          {helperText ? <FormHelperText variant="filled">{helperText}</FormHelperText> : null}
        </FormControl>
      );
    }

    // Sort the Autocomplete options based on the Sort Preferences
    const sortAutocompleteUsingSelectedSortPreference = (a, b) => {
      const { key, sortByAsc } = sortBy;

      let aValue = a[key];
      let bValue = b[key];

      if (typeof aValue === 'number') {
        // Compare as numbers
        if (sortByAsc) {
          return aValue - bValue;
        }
        return bValue - aValue;
      }

      // Compare as strings
      aValue = aValue ? aValue.toString().toLowerCase() : '';
      bValue = bValue ? bValue.toString().toLowerCase() : '';

      if (sortByAsc) {
        return aValue.localeCompare(bValue);
      }
      return bValue.localeCompare(aValue);
    };

    initialOptions.sort(sortAutocompleteUsingSelectedSortPreference);

    // Compile our parent Autocomplete options.
    const options = initialOptions.map((option) => {
      if (!sortByOptions) {
        return [];
      }

      let type = sortBy.key !== sortByOptions[0].value ? typeof option[sortBy.key] : null;
      if (type === 'string') {
        type = formatDateColumns.includes(sortBy.key) ? 'date' : 'string';
      }

      return {
        label: option[sortByOptions[0].value],
        // If we are sorting on something other than the name, we will also want to display this next to the name in the Autocomplete.
        // ie (Automation Name) when sorting on name
        // ie (08/26/2023 - Automation Name) when sorting on created_at
        extraLabel: sortBy.key !== sortByOptions[0].value ? { value: option[sortBy.key], type } : null,
        value: option.value,
      };
    });

    const isOptionEqualToValue = (option, selectedValue) => {
      return String(option.value) === String(selectedValue.value);
    };

    const findCurrentValue = (field) => {
      const isValueNotInStateYet = value === null;
      const isOkayToUseValueFromHookForm = isValueNotInStateYet || field.value === value;

      if (field.value && isOkayToUseValueFromHookForm) {
        return options.find((element) => String(element.value) === String(field.value));
      }
      if (defaultValue) {
        return { label: defaultValue, value: null };
      }
      return value;
    };

    const handleSortByClick = (event, index) => {
      let newSortPreference;
      if (sortBy.key === sortByOptions[index].value) {
        // If the user selects the already selected option, switch the sort order.
        newSortPreference = { key: sortBy.key, sortByAsc: !sortBy.sortByAsc };
      } else {
        // If the user selected a new option, set it.
        newSortPreference = {
          key: sortByOptions[index].value,
          sortByAsc: sortByOptions[index].type === 'string', // if it's a String, set to ASC, if it's a number or date, set to ASC
        };
      }
      setSortBy(newSortPreference);
      if (localStorageSortPreferenceName) {
        setLocalStorageWithExpiry(localStorageSortPreferenceName, newSortPreference, 7 * 60 * 60 * 1000);
      }
    };

    function buildSortByMenuOption(option) {
      if (option.value === sortBy.key) {
        // This is the selected option.
        // We need to display the Sort Order next to it.
        return (
          <>
            <ListItemIcon style={{ minWidth: 0, marginRight: 8 }}>
              {sortBy.sortByAsc ? (
                <ArrowUpward style={{ fontSize: '16px' }} />
              ) : (
                <ArrowDownward style={{ fontSize: '16px' }} />
              )}
            </ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </>
        );
      }
      // This is not the selected option.
      // Don't display the Sort Order next to it.
      return (
        <ListItemText inset style={{ marginLeft: -12 }}>
          {option.label}
        </ListItemText>
      );
    }

    return (
      <Controller
        render={({ field: { ref, ...field }, fieldState: { invalid, error }, formState: { isSubmitting } }) => {
          const currentValue = findCurrentValue(field);
          return (
            <Autocomplete
              key={`${currentValue?.value}-rerender`}
              value={currentValue}
              inputValue={inputValue}
              getOptionLabel={(option) => option.label ?? ''}
              renderOption={(props, option) => [props, option]}
              onChange={(e, newValue) => {
                setValue(newValue);
                field.onChange(newValue ? newValue.value : null);
                onChange(newValue);
              }}
              onInputChange={(_, newInputValue) => {
                if (value === null) {
                  // this if is important for when the form was provided an initial value.
                  // onInputChange apparently fires but onChange does not in that situation.
                  // value not in state yet, correct that now.
                  const optionMatchingInputValue =
                    options?.find((element) => String(element.label) === String(newInputValue)) ?? null;
                  setValue(optionMatchingInputValue);
                }
                setInputValue(newInputValue);
                onInputChange(_);
              }}
              onBlur={field.onBlur}
              options={options}
              disabled={disabled || isSubmitting}
              disableClearable={false}
              noOptionsText="No options were found"
              size="small"
              PopperComponent={StyledPopper}
              ListboxComponent={ListboxComponent}
              isOptionEqualToValue={isOptionEqualToValue}
              open={autocompletePopupOpen}
              onOpen={() => setAutocompletePopupOpen(true)}
              onClose={(event) => {
                // Close the Autocomplete unless we are interacting with the Sort Preference menu.
                if (event?.relatedTarget?.id !== `${name}.sortPreferenceMenu`) {
                  setAutocompletePopupOpen(false);
                  sortPreferencePopupState.close();
                }
              }}
              fullWidth={!width}
              sx={{ ...sx, width }}
              renderInput={(params) => {
                // This will add the Sort Options button to the Autocomplete.
                const appendedParams = sortByOptions
                  ? {
                      ...params,
                      InputProps: {
                        ...params?.InputProps,
                        endAdornment: {
                          ...params?.InputProps?.endAdornment,
                          props: {
                            ...params?.InputProps?.endAdornment?.props,
                            children: [
                              <Fragment key="sortableMenu">
                                <IconButton
                                  size="small"
                                  sx={{ marginRight: '-8px' }}
                                  disabled={disabled || !sortByOptions}
                                  {...bindToggle(sortPreferencePopupState)}
                                  onClick={(eventAnchorOrEl) => {
                                    sortPreferencePopupState.toggle(eventAnchorOrEl);
                                    setAutocompletePopupOpen(true);
                                  }}
                                >
                                  <ImportExport id={`${name}.sortPreferencesIcon`} />
                                </IconButton>
                                <Menu
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  sx={{
                                    my: 0,
                                    py: 0,
                                    zIndex: (theme) => theme.zIndex.modal + 1,
                                  }}
                                  {...bindMenu(sortPreferencePopupState)}
                                >
                                  <Box paddingLeft={2} paddingBottom={1}>
                                    <Typography>Sort By</Typography>
                                  </Box>
                                  {sortByOptions
                                    ? sortByOptions.map((option, index) => (
                                        <MenuItem
                                          key={option.value}
                                          selected={option.value === sortBy.key}
                                          onClick={(event) => handleSortByClick(event, index)}
                                          id={`${name}.sortPreferenceMenu`} // Allows us to interact with the SortBy menu without closing the parent Autocomplete menu.
                                        >
                                          {buildSortByMenuOption(option)}
                                        </MenuItem>
                                      ))
                                    : null}
                                </Menu>
                              </Fragment>,
                              params?.InputProps?.endAdornment?.props?.children[1],
                            ],
                          },
                        },
                      },
                    }
                  : { ...params };

                return (
                  <TextField
                    {...appendedParams}
                    inputRef={ref}
                    error={invalid}
                    helperText={error ? error.message : helperText}
                    label={label}
                    required={required}
                    autoFocus={autoFocus}
                    variant="outlined"
                    onMouseDown={onMouseDown}
                    InputLabelProps={field.value !== null ? { shrink: true } : {}}
                    inputProps={{
                      ...appendedParams.inputProps,
                      // Setting autocomplete as 'new-password' is a trick to tell the browser not to attempt to autocomplete the input.
                      // https://stackoverflow.com/questions/50347574/how-to-disable-chrome-autocomplete-feature
                      autoComplete: 'new-password',
                      style: useLabelsAsFonts ? { fontFamily: `${findCurrentValue(field)?.label}, Karla, Arial` } : {},
                    }}
                  />
                );
              }}
            />
          );
        }}
        name={name}
      />
    );
  }
);

HookFormAutocompleteSortable.propTypes = {
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onMouseDown: PropTypes.func,
  required: PropTypes.bool,
  localStorageSortPreferenceType: PropTypes.oneOf([
    'AUTOMATIONS',
    'EMAILS',
    'NEXT_GEN_EMAILS',
    'FORMS',
    'FORM_MAPPINGS',
    'LANDING_PAGES',
    'MARKETING_LISTS',
    'SEGMENTS',
    'SMS_MESSAGES',
    'SUBSCRIPTION_LISTS',
    'TAGS',
    'VIDEOS',
    'WEBINARS',
  ]),
  sx: PropTypes.object,
  useLabelsAsFonts: PropTypes.bool,
  width: PropTypes.number,
};

HookFormAutocompleteSortable.defaultProps = {
  autoFocus: false,
  defaultValue: null,
  disabled: false,
  helperText: null,
  loading: false,
  onChange: () => {},
  onInputChange: () => {},
  onMouseDown: () => {},
  required: false,
  localStorageSortPreferenceType: null,
  sx: {},
  useLabelsAsFonts: false,
  width: null,
};

export default HookFormAutocompleteSortable;
