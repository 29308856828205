import React, { useState } from 'react';
import { Dialog, IconButton, Slide, Tooltip } from '@mui/material';
import { NewReleases } from '@mui/icons-material';
import DialogTitle from '@/components/core/DialogTitle';
import FeatureReleaseBlogPostsFeed from '@/components/PamingaWebsite/FeatureReleaseBlogPostsFeed';

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" timeout={1700} ref={ref} {...props} />;
});

const FeatureReleaseDialog = ({
  color,
  iconOnly,
  iconSize,
}: {
  color?: string;
  iconOnly?: boolean;
  iconSize?: number;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Tooltip title="What's New in Paminga" enterDelay={700} arrow>
        {iconOnly ? (
          <NewReleases
            sx={{
              fontSize: iconSize || 'inherit',
              color: color || ((theme) => theme.palette.grey[500]),
              cursor: 'pointer',
              transition: 'all 300ms ease',
              '&:hover': {
                transform: 'scale(1.2)',
              },
            }}
            onClick={() => {
              openDialog();
            }}
          />
        ) : (
          <IconButton
            size="medium"
            edge="end"
            onClick={() => {
              openDialog();
            }}
            sx={{
              '&:hover': {
                backgroundColor: 'background.offset',
              },
            }}
          >
            <NewReleases
              sx={{
                fontSize: iconSize || 'inherit',
                color: color || 'palette.techBlue',
                cursor: 'pointer',
              }}
            />
          </IconButton>
        )}
      </Tooltip>

      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth="md"
        // @ts-ignore
        TransitionComponent={Transition}
        aria-labelledby="Paminga Feature Release Feed"
      >
        <DialogTitle title="What's New" additionalTitle="Recent Feature Releases" onClose={closeDialog} />
        <FeatureReleaseBlogPostsFeed dialogOpen={dialogOpen} />
      </Dialog>
    </>
  );
};

FeatureReleaseDialog.defaultProps = {
  color: null,
  iconOnly: false,
  iconSize: null,
};

export default FeatureReleaseDialog;
